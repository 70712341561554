.GridLayout {
  .grid-header {
    justify-content: flex-start;
    padding: 10px 0;

    .grid-title {
      margin-right: 10px;
    }

    .show-more a {
      background-color: var(--primary-btn-background);
      padding: 5px;
    }
  }

  &.sport {
    .item > div:not(.Game) {
      .content {
        margin: 0;
        padding: 0 0 10px 10px;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      a:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(31, 31, 31, 0), rgba(7, 7, 7, 0.8));
      }
    }
  }
}
