body .SessionSummary {
    font-size: 12px;
  }

body .SessionSummary .Header h1,
      body .SessionSummary .Header h2,
      body .SessionSummary .Header h3,
      body .SessionSummary .Header h4,
      body .SessionSummary .Header h5,
      body .SessionSummary .Header h6 {
        margin: 0;
      }

body .SessionSummary .Summary {
      margin-bottom: 20px;
    }

body .SessionSummary .Summary .date {
        display: inline;
        color: #aaaaaa;
      }

body .SessionSummary .Summary .col {
        display: grid;
        grid-gap: 0 15px;
      }

body .SessionSummary .Summary .col.col-1 {
          grid-template-columns: repeat(1, 1fr);
        }

body .SessionSummary .Summary .col.col-2 {
          grid-template-columns: repeat(2, 1fr);
          margin-top: 20px;
        }

body .SessionSummary .Summary .info {
        margin-top: 5px;
      }

body .SessionSummary .Summary .info span {
          white-space: nowrap;
        }

body .SessionSummary .Summary .info span:nth-child(2) {
            color: #aaaaaa;
          }

body .SessionSummary .Summary .info span.green-value {
          color: #ffc700;
        }

body .SessionSummary .deposit-limit h3 {
        font-size: 14px;
        font-weight: 700;
        margin: 10px 0 5px 0;
      }

body .SessionSummary .deposit-limit .limits {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }

body .SessionSummary .deposit-limit .limits span {
          width: 100%;
        }

body .SessionSummary .ResponsibleGaming {
      margin-top: 20px;
    }

body .SessionSummary .ResponsibleGaming h2,
      body .SessionSummary .ResponsibleGaming h3 {
        font-size: 14px;
        font-weight: 700;
        margin: 10px 0 5px 0;
      }

body .SessionSummary .ResponsibleGaming p {
        margin: 0;
      }

body .SessionSummary .ResponsibleGaming a {
        color: #42cc5f;
      }
