body .IndexContainerPnp {
  background-color: #015307;
  .main-content-container {
    margin-top: 0;
    background-position: top;
    background-size: 100% auto;
    color: var(--white);

    @media (max-width: 992px) {
      background-size: auto;
      background-position: bottom;
    }

    .index__header {
      padding: 20px;
      .index__header__logo {
        display: none;
      }
      .index__header__actions {
        align-items: center;
        .index__header__actions__menu {
          display: initial;
          img {
            height: auto;
          }
        }
        .index__header__actions__home {
          display: none;
        }
      }
    }
    .index-container-full {
      .fixed-position {
        margin-top: 10px;

        .PayNPlay {
          margin-top: 30px;
        }
        .brand-description {
          font-size: 25px;
          line-height: 1.1;
          p {
            margin: 0;
          }
        }
        .actions {
          .button {
            width: auto;
            padding: 0 3em;
            font-size: 2em;
            text-transform: uppercase;
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
          }
        }
        .Features,
        .Carousel {
          .feature.link,
          .feature {
            .feature-image {
              img {
                height: 70px;
              }
            }
            .feature-content {
              h1,
              h2,
              h3,
              h4,
              h5 {
                margin: 10px 5px 5px;
                text-transform: uppercase;
                font-size: 16px;
              }
              p {
                margin: 0;
              }
            }
          }
        }
      }
    }
    @media (max-width: 320px) {
      .index-container-full {
        .fixed-position {
          .actions {
            .button {
              padding: 0 2.5em;
            }
          }
        }
      }
    }
  }
  .Footer {
    background-color: #015307;
    .topSection {
      background-color: #015307;
    }
  }
}

.is-account .IndexContainerPnp {
  .main-content-container {
    .index-container-full {
      .fixed-position {
        .actions {
          flex-direction: column;
          align-items: center;
          .button {
            width: fit-content;
          }
          .sign-in {
            .button {
              background: none;
              box-shadow: none;
            }
          }
        }
      }
    }
    @media (max-width: 992px) {
      .index-container-full {
        .fixed-position {
          .actions {
            .button {
              font-size: 1.5em;
            }
          }
        }
      }
    }
  }
}
