.Game {
  &__favorite-heart {
    z-index: 10;
  }

  .jackpot {
    bottom: unset;
    color: var(--white);
  }

  .thumbnail {
    z-index: -1;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(rgba(31, 31, 31, 0), rgba(7, 7, 7, 0.8));
    }
  }

  .game-info {
    color: var(--white);
    line-height: initial;
    margin: 0;
    padding: 0 0 10px 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
  }

  .badges {
    bottom: initial;
    top: 5px;
  }

  &:hover {
    @media (min-width: 992px) {
      .favorite-heart {
        display: initial;
      }

      .jackpot,
      .ribbon,
      .badges {
        display: none;
      }

      &:before {
        display: block;
      }
    }
  }

  &:before {
    pointer-events: none;
    color: var(--white);
    font-family: 'icomoon';
    font-size: 32px;
    content: '\e800';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 60px;
    height: 60px;
    line-height: 60px;
    display: none;
    text-indent: 18px;
    border-radius: 50%;
    /* Override tg-base */
    background: var(--primary-color) !important;
  }
}

.GameGrid--extra-margin-on-mobile .Game {
  margin: 0;
}
