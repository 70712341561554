button,
.button {
  font-weight: 900;
  text-transform: uppercase;

  &[disabled] {
    background: var(--dark-gray);

    &:hover {
      background: var(--dark-gray);
    }
  }

  &.tertiary,
  .tertiary-button {
    color: var(--tertiary-btn-color);
    background: var(--tertiary-btn-background);

    &:hover {
      background: var(--tertiary-btn-background-hover);
    }
  }
}
