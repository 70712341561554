.Game .live-casino-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;
    margin: 4px 0 0;
  }
    .Game .live-casino-info .wager-limits {
      font-weight: 700;
      font-size: 10px;
    }
    .Game .live-casino-info .roulette-numbers {
      display: flex;
      flex-flow: row-reverse;
      align-items: center;
      font-weight: 700;
      font-size: 10px;
      height: 25px;
    }
    .Game .live-casino-info .roulette-numbers .number {
        margin: 0 3px;
        width: 12px;
        height: 16px;
        text-align: center;
        font-size: 11px;
        display: flex;
        justify-content: center;
      }
    .Game .live-casino-info .roulette-numbers .number.red:after,
        .Game .live-casino-info .roulette-numbers .number.red:before {
          background: #b20000;
        }
    .Game .live-casino-info .roulette-numbers .number.black:after,
        .Game .live-casino-info .roulette-numbers .number.black:before {
          background: #181818;
        }
    .Game .live-casino-info .roulette-numbers .number.green:after,
        .Game .live-casino-info .roulette-numbers .number.green:before {
          background: #007523;
        }
    .Game .live-casino-info .roulette-numbers .number:before,
        .Game .live-casino-info .roulette-numbers .number:after {
          content: ' ';
          display: block;
          position: absolute;
          width: 12px;
          height: 16px;
          z-index: -1;
        }
    .Game .live-casino-info .roulette-numbers .number:before {
          margin-left: -2px;
        }
    .Game .live-casino-info .roulette-numbers .number:after {
          margin-left: 2px;
        }
    .Game .live-casino-info .roulette-numbers .number:nth-child(odd):before {
            transform: skew(10deg, 0deg);
          }
    .Game .live-casino-info .roulette-numbers .number:nth-child(odd):after {
            transform: skew(-10deg, 0deg);
          }
    .Game .live-casino-info .roulette-numbers .number:nth-child(even):before {
            transform: skew(-10deg, 0deg);
          }
    .Game .live-casino-info .roulette-numbers .number:nth-child(even):after {
            transform: skew(10deg, 0deg);
          }
    .Game .live-casino-info .roulette-numbers .number:first-child {
          margin: 0 8px;
          transform: scale(1.5);
          font-size: 9px;
          align-items: center;
        }
    .Game .live-casino-info .roulette-numbers .number:first-child:before {
            border: 1px solid white;
            border-right: 0;
          }
    .Game .live-casino-info .roulette-numbers .number:first-child:after {
            border: 1px solid white;
            border-left: 0;
          }
    .Game .live-casino-info .blackjack-info {
      display: flex;
      align-items: center;
      font-size: 10px;
    }
    .Game .live-casino-info .blackjack-info .seats,
      .Game .live-casino-info .blackjack-info .unlimited-seats,
      .Game .live-casino-info .blackjack-info .bet-behind {
        border-radius: 100%;
        border: 1px solid #fff;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        margin: 0 0 0 5px;
      }
    .Game .live-casino-info .blackjack-info .seats.red, .Game .live-casino-info .blackjack-info .unlimited-seats.red, .Game .live-casino-info .blackjack-info .bet-behind.red {
          background: #b20000;
        }
    .Game .live-casino-info .blackjack-info .seats.orange, .Game .live-casino-info .blackjack-info .unlimited-seats.orange, .Game .live-casino-info .blackjack-info .bet-behind.orange {
          background: #d95e01;
        }
    .Game .live-casino-info .blackjack-info .seats.yellow, .Game .live-casino-info .blackjack-info .unlimited-seats.yellow, .Game .live-casino-info .blackjack-info .bet-behind.yellow {
          background: #bca700;
        }
    .Game .live-casino-info .blackjack-info .seats.green, .Game .live-casino-info .blackjack-info .unlimited-seats.green, .Game .live-casino-info .blackjack-info .bet-behind.green {
          background: #007523;
        }
    .Game .live-casino-info .blackjack-info .seats.gray, .Game .live-casino-info .blackjack-info .unlimited-seats.gray, .Game .live-casino-info .blackjack-info .bet-behind.gray {
          background: #707070;
        }
    .Game .live-casino-info .blackjack-info .unlimited-seats img {
          width: 13px;
        }
    .Game .live-casino-info .blackjack-info .bet-behind img {
          width: 11px;
        }
    @media (max-width: 992px) {.Game .live-casino-info {
      display: block;
      height: auto
  }
      .Game .live-casino-info .wager-limits {
        font-size: 10px;
        line-height: 13px;
      }
      .Game .live-casino-info .roulette-numbers {
        position: inherit;
        transform: scale(0.8);
        height: 20px;
        transform-origin: left bottom;
        justify-content: flex-end;
        align-items: flex-start;
      }
          .Game .live-casino-info .roulette-numbers .number:first-child {
            line-height: 9px;
          }
      .Game .live-casino-info .blackjack-info {
        position: inherit;
      }
        .Game .live-casino-info .blackjack-info .seats,
        .Game .live-casino-info .blackjack-info .unlimited-seats,
        .Game .live-casino-info .blackjack-info .bet-behind {
          width: 17px;
          height: 17px;
          font-size: 8px;
          margin: 0 0 0 2px;
        }
          .Game .live-casino-info .blackjack-info .seats img, .Game .live-casino-info .blackjack-info .unlimited-seats img, .Game .live-casino-info .blackjack-info .bet-behind img {
            width: 9px;
          }
          .Game .live-casino-info .blackjack-info .bet-behind img {
            width: 7px;
          }
    }
