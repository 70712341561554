.Footer {
  background-color: var(--black);

  .footer-logo {
    display: none;
  }

  .licence {
    div {
      display: flex !important;

      p a {
        display: flex;
        flex-flow: column;
        text-decoration: none;

        span {
          font-size: 12px;
        }

        svg {
          height: 40px;
          margin-right: 0;
        }
      }
    }
  }

  .topSection {
    background-color: var(--black);

    .container {
      @media (min-width: 993px) {
        flex-direction: row;
      }
    }
  }
}