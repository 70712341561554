.bonus-footer {
  height: 55px;

  .bonus-background {
    display: none;
  }

  @media (max-width: 992px) {
    bottom: 55px;
  }
}

.BonusStep {
  a {
    text-decoration: underline;
  }
}
