@font-face {
  font-family: 'icomoon';
  src: url('/icomoon/icomoon.eot?6nedly');
  src: url('/icomoon/icomoon.eot?6nedly#iefix') format('embedded-opentype'),
    url('/icomoon/icomoon.ttf?6nedly') format('truetype'),
    url('/icomoon/icomoon.woff?6nedly') format('woff'),
    url('/icomoon/icomoon.svg?6nedly#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-download:before {
  content: '\e94f';
}

.icon-map-marker:before {
  content: '\e946';
}

.icon-money-bill:before {
  content: '\e947';
}

.icon-flag:before {
  content: '\e900';
}

.icon-lock:before {
  content: '\e901';
}

.icon-book:before {
  content: '\e902';
}

.icon-times-circle:before {
  content: '\e903';
}

.icon-list:before {
  content: '\e904';
}

.icon-key:before {
  content: '\e905';
}

.icon-user:before {
  content: '\e906';
}

.icon-check-square:before {
  content: '\e907';
}

.icon-cog:before {
  content: '\e908';
}

.icon-chevron-circle-right:before {
  content: '\e909';
}

.icon-chevron-circle-left:before {
  content: '\e90a';
}

.icon-avatar:before {
  content: '\e90b';
}

.icon-live-casino:before {
  content: '\e90c';
}

.icon-sports:before {
  content: '\e90d';
}

.icon-app:before {
  content: '\e90e';
}

.icon-angle-left:before {
  content: '\e90f';
}

.icon-angle-right:before {
  content: '\e910';
}

.icon-caret-down:before {
  content: '\e911';
}

.icon-check:before {
  content: '\e912';
}

.icon-promotions:before {
  content: '\e913';
}

.icon-chevron-left:before {
  content: '\e914';
}

.icon-headphones:before {
  content: '\e915';
}

.icon-chevron-right:before {
  content: '\e916';
}

.icon-times:before {
  content: '\e917';
}

.icon-coins:before {
  content: '\e918';
}

.icon-cogs:before {
  content: '\e919';
}

.icon-dollar-sign:before {
  content: '\e91a';
}

.icon-envelope:before {
  content: '\e91b';
}

.icon-heart-o:before {
  content: '\e91c';
}

.icon-pencil:before {
  content: '\e91d';
}

.icon-trash:before {
  content: '\e91e';
}

.icon-casino:before {
  content: '\e91f';
}

.icon-chevron-down:before {
  content: '\e920';
}

.icon-heart:before {
  content: '\e921';
}

.icon-exclamation-triangle:before {
  content: '\e922';
}

.icon-angle-down:before {
  content: '\e923';
}

.icon-phone:before {
  content: '\e924';
}

.icon-external-link:before {
  content: '\e925';
}

.icon-plus:before {
  content: '\e926';
}

.icon-minus:before {
  content: '\e927';
}

.icon-info-circle:before {
  content: '\e928';
}

.icon-history:before {
  content: '\e929';
}

.icon-question-circle:before {
  content: '\e92a';
}

.icon-stop-circle:before {
  content: '\e92b';
}

.icon-search:before {
  content: '\e92c';
}

.icon-sign-in:before {
  content: '\e92d';
}

.icon-ticket:before {
  content: '\e92e';
}

.icon-user-circle:before {
  content: '\e92f';
}

.icon-youtube:before {
  content: '\e930';
}

.icon-twitter:before {
  content: '\e931';
}

.icon-facebook:before {
  content: '\e932';
}

.icon-instagram:before {
  content: '\e933';
}

.icon-check-circle:before {
  content: '\e934';
}

.icon-fist:before {
  content: '\e935';
}

.icon-gameplay-menu:before {
  content: '\e936';
}

.icon-cc-visa:before {
  content: '\e937';
}

.icon-stopwatch:before {
  content: '\e938';
}

.icon-lightbulb-off:before {
  content: '\e939';
}

.icon-lightbulb-on:before {
  content: '\e93a';
}

.icon-expand:before {
  content: '\e93b';
}

.icon-cc-mastercard:before {
  content: '\e93c';
}

.icon-virtual-sports:before {
  content: '\e93d';
}

.icon-compress:before {
  content: '\e93e';
}

.icon-home:before {
  content: '\e93f';
}

.icon-ellipsis-v:before {
  content: '\e940';
}

.icon-bars:before {
  content: '\e941';
}

.icon-angle-up:before {
  content: '\e942';
}

.icon-check-circle-o:before {
  content: '\e943';
}

.icon-hashtag:before {
  content: '\e944';
}

.icon-close:before {
  content: '\e945';
}

.icon-clock:before {
  content: '\e948';
}

.icon-snowflake:before {
  content: '\e949';
}

.icon-users:before {
  content: '\e94a';
}

.icon-caret-up:before {
  content: '\e94b';
}

.icon-eye:before {
  content: '\e94c';
}

.icon-eye-slash:before {
  content: '\e94d';
}

.icon-exclamation-circle:before {
  content: '\e94e';
}

.icon-refresh:before {
  content: '\e984';
}
