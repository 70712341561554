@import './colors.css';

.Banners {
  .slick-slider .slick-list .slick-track .slick-slide {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .button {
      margin: 25px 0;
      font-size: 1.2rem;
    }

    .content {
      width: 100%;
      max-width: 1900px;
      padding: 0 20px;
      position: relative;
      margin-bottom: 20px;

      /* reset styles from tg-base */
      left: auto !important;
      top: auto !important;
      bottom: auto !important;
    }
    .disclaimer {
      left: 20px;
      bottom: 20px;
      font-size: 12px;
    }
  }

  .slick-slider .slick-arrow {
    &.slick-next,
    &.slick-prev {
      display: none !important;
    }
  }

  .slick-dots {
    bottom: 0;

    li {
      button::before {
        color: var(--white);
        font-size: 10px;
        opacity: 1;
      }

      &.slick-active {
        button::before {
          color: var(--primary-color);
          font-size: 10px;
          opacity: 1;
        }
      }
    }
  }
}

.jurisdiction-sga.is-authenticated .Banners {
  margin-top: calc(var(--header-height) + var(--rg-bar-height));
}

@media (max-width: 992px) {
  .Banners {
    min-height: 60vh !important;
    max-height: 60vh !important;
    margin-top: calc(var(--header-height-mobile) + var(--sticky-footer-height));
    .slick-slider .slick-list .slick-track .slick-slide {
      .content {
        padding: 0 10px;
      }
    }
  }

  .jurisdiction-sga.is-authenticated .Banners {
    margin-top: calc(var(--header-height-mobile) + var(--rg-bar-height));
  }
}
