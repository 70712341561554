body {
  color: var(--primary-text-color);
  background-color: #f7f7f7;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}

.main-content-container {
  color: var(--primary-text-color);
  margin-top: var(--header-height);

  @media (max-width: 992px) {
    margin-top: calc(var(--header-height-mobile) + var(--sticky-footer-height));
  }
}

.jurisdiction-sga.is-authenticated
  .BasePage:not(.IndexContainer)
  .main-content-container {
  margin-top: calc(var(--header-height) + var(--rg-bar-height));

  @media (max-width: 992px) {
    margin-top: calc(var(--header-height-mobile) + var(--rg-bar-height));
  }
}

.h1,
h1 {
  font-size: 2.5em;
  margin: 0 0 0.67em 0;
}
.h2,
h2 {
  font-size: 2em;
  margin: 0 0 0.83em 0;
}
.h3,
h3 {
  font-size: 1.75em;
  margin: 0 0 1em 0;
}
.h4,
h4 {
  font-size: 1.5em;
  margin: 0 0 1.33em 0;
}
.h5,
h5 {
  font-size: 1.25em;
  margin: 0 0 1.67em 0;
}
.h6,
h6 {
  font-size: 1em;
  margin: 0 0 2.33em 0;
}

.button,
button,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 900;
}

a {
  text-decoration: underline;
}

@media (max-width: 992px) {
  .h1,
  h1 {
    font-size: 2em;
  }
  .h2,
  h2 {
    font-size: 1.5em;
  }
  .h3,
  h3 {
    font-size: 1.17em;
  }
  .h4,
  h4 {
    font-size: 1em;
  }
  .h5,
  h5 {
    font-size: 0.83em;
  }
  .h6,
  h6 {
    font-size: 0.67em;
  }

  .SettingsTab {
    bottom: 55px;
  }
}

@media (max-width: 992px) {
  body:not(.is-authenticated) {
    .main-content-container {
      margin-top: calc(
        var(--header-height-mobile) + var(--sticky-footer-height)
      );
    }
  }
}

@media (min-width: 993px) {
  #fc_frame:not(.fc-open) {
    display: initial;
  }

  .sidebar-is-open {
    #fc_frame:not(.fc-open) {
      display: none;
    }
  }
}
