.column-wrapper {
  padding: 0;
  background-color: var(--light-gray);

  .columns {
    max-width: initial;

    .column {
      overflow: hidden;
      position: relative;
      flex: 1;

      div {
        text-align: center;
        color: var(--primary-text-color);
        padding: 10px 0;

        p {
          line-height: 12px;
        }

        > * {
          margin: 0;
        }
      }

      &:nth-child(2) {
        &:before {
          left: -20px;
        }

        &:after {
          right: -20px;
        }

        &:after,
        &:before {
          content: '';
          height: 8px;
          width: 140px;
          background: var(--primary-color);
          position: absolute;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
