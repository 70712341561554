@import '../skin/theme/colors.css';

.tg-input,
.tg-select,
.tg-password-input,
.tg-phonenumber-input,
.tg-birthdate-input,
.tg-checkbox,
.tg-autocomplete-input {
  &__status-text,
  &__helper-text {
    font-size: 0.85em;
  }

  &__status-text {
    min-height: 1.5em;
    line-height: 1.5em;
  }

  &--status-failure &__status-text {
    color: var(--error);
  }
}

.tg-password-input {
  .tg-password-input__toggle.icon {
    pointer-events: all;
  }
}

.tg-phonenumber-input {
  display: flex;
  flex-flow: row wrap;

  .Select {
    flex-basis: 120px;
    margin-right: 0.5em;
  }

  .Input {
    flex: 1 1 1%;
  }

  &__status-text,
  &__helper-text {
    width: 100%;
  }
}

.tg-autocomplete-input {
  position: relative;

  &__suggestions {
    position: absolute;
    top: 48.75px;
    background: #eee;
    width: 100%;
    color: #000;
    z-index: 1;
    border-radius: 2px;
    max-height: 300px;
    overflow: auto;

    &__suggestion {
      cursor: pointer;
      padding: 10px;
      border-bottom: 1px solid #000;

      &--selected {
        background: #dcdcdc;
      }
    }
  }
}

.tg-birthdate-input {
  display: flex;
  flex-flow: row wrap;

  &__label {
    width: 100%;
  }

  .Select {
    flex: 1 1 1%;
    margin-left: 0.5em;
  }
  .Select:first-of-type {
    margin-left: 0;
  }

  &__status-text,
  &__helper-text {
    width: 100%;
  }
}

.Input,
.Select {
  position: relative;
  overflow: hidden;

  &--type-hidden {
    display: none;
  }

  &__input,
  &__select {
    display: block;
    appearance: none;
    border: none;
    outline: none;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: calc(10px + var(--leading-lane-width));
    padding-right: calc(10px + var(--trailing-lane-width));
    font-size: 14px;
    line-height: 1.8em;
    width: 100%;

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
  &--has-label {
    .Input__input,
    .Select__select {
      padding-top: 17px;
      padding-bottom: 3px;

      &::placeholder {
        font-size: 1em;
        transition: color 0.17s, font-size 0.17s;
      }

      option {
        color: var(--black);
      }
    }
  }
  &--has-label:not(&--has-focus) {
    .Input__input::placeholder {
      font-size: 0.9em;
      color: transparent;
    }
  }

  &__label {
    position: absolute;
    top: 50%;
    left: calc(10px + var(--leading-lane-width));
    pointer-events: none;
    font-size: 1em;
    white-space: nowrap;
    transform: translateY(-50%);
    transform-origin: left;
    transition: transform 0.15s;
  }
  &--has-focus,
  &--has-value {
    .Input__label {
      transform: translateY(-106%) scale(0.75);
    }
  }
  &--has-value {
    .Select__label {
      transform: translateY(-106%) scale(0.75);
    }
  }

  &--has-label&:not(&--has-value) {
    .Select__select {
      color: transparent;
    }
  }

  &--status-failure {
    caret-color: var(--error);

    .Input,
    .Select {
      &__label {
        color: var(--error);
      }
      &__status-line {
        background-color: var(--error);
      }
    }
  }

  &--status-success {
    .Input,
    .Select {
      &__label {
        color: var(--success);
      }
      &__status-line {
        background-color: var(--success);
      }
    }
  }

  &__leading-lane,
  &__trailing-lane {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    pointer-events: none;
    color: var(--primary-color);

    .icon,
    svg {
      font-size: 20px;
      width: 20px;
      height: 20px;
      text-align: center;
      pointer-events: none;
    }

    &:last-child {
      margin-left: 0;
      margin-right: 0;
    }
  }
  &__leading-lane {
    left: 0px;

    > * {
      margin-left: 10px;
    }
  }
  &__trailing-lane {
    right: 0px;

    > * {
      margin-right: 10px;
    }
  }

  &__status-line {
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    transform: scaleX(0);
    opacity: 0;
    transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
    background-color: var(--primary-color);

    &--active {
      transform: scaleX(1);
      opacity: 1;
    }
  }

  .SuccessIcon {
    color: var(--success);
  }
  .FailureIcon {
    color: var(--error);
  }
}

input[type='radio'] {
  -webkit-appearance: none;
  min-width: 20px;
  height: 20px;
  background: transparent;
  border-radius: 50%;
  border: 2px solid #484848;
  margin-right: 12px;
}

input[type='radio']:checked {
  background-color: #151515;
  border: 5px solid var(--primary-color);
}

@media (max-width: 992px) {
  .Input,
  .Select {
    &__input,
    &__select {
      font-size: 16px !important;
    }
  }
}
