.OffCanvas.GlobalNavContainer {
  .canvas-content {
    background: transparent;
    padding: 0;
  }

  .GlobalNavContainer__link {
    &--active {
      background: var(--gray);
    }

    img {
      height: 18px;
      width: auto;
      margin-right: 5px;
    }
  }

  .section-wrapper {
    padding: 10px 0;
    background: var(--gray);
  }

  .support-button-wrapper {
    background-color: var(--gray);
    border-top: 1px solid var(--dark-gray);
  }
}

@media (max-width: 992px) {
  .OffCanvas.GlobalNavContainer {
    .GlobalNavContainer__button {
      margin: 0 15px;
      width: calc(100% - 30px);

      &--support {
        margin: 20px 15px;
      }
    }

    .GlobalNavContainer__link {
      padding: 7px 0 7px 15px;
    }
  }
}
