.notification {
  background: var(--white);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

  .notification-button {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
}

@media (max-width: 600px) {
  .notification {
    top: calc(var(--header-height-mobile) + 10px);
  }

  .jurisdiction-sga.is-authenticated .notification {
    top: calc(var(--header-height-mobile) + var(--rg-bar-height) + 10px);
  }
}
