$size-xs: 540px;
$size-sm: 1200px;
$size-md: 1600px;
$size-lg: 2400px;

.GameGridList .category-row {
  @media (min-width: 1025px) {
    &.category-row {
      .slider {
        .list .track .items .slide-item {
          min-width: calc(calc(100vw / 16) - 6.25px);
          height: 54px;
          @media (max-width: $size-lg) {
            min-width: calc(calc(100vw / 12) - 8.3px);
          }
          @media (max-width: $size-md) {
            min-width: calc(calc(100vw / 10) - 10px);
          }

          @media (max-width: $size-sm) {
            min-width: calc(calc(100vw / 8) - 12.5px);
          }
        }
      }
    }
  }
  @media (max-width: 1025px) {
    .slider .list .track .items {
      .slide-item {
        min-width: 40vw;
        height: 80px;
        /* This is so the scroll bar wont be placed on top of the title */
        margin-bottom: 3px;
        &:last-child {
          margin-right: 10px;
        }
        @media (min-width: 700px) {
          min-width: 20vw;
        }
        @media (min-width: 992px) {
          min-width: 12.5vw;
          width: 12.5vw;
        }
        .CasinoCategoryRow {
          width: 100%;
          a {
            display: flex;
            flex-flow: column;
            height: 100%;
            text-decoration: none;
            .category-name {
              position: inherit;
              .title {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

.CasinoCategoryRow {
  height: 100%;
  width: 100%;
  text-align: center;
  position: relative;
  a {
    .thumbnail {
      background: var(--light-gray);
      border-radius: 6px;
      .placeholder-thumbnail {
        display: none;
      }
      .category-icon {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 8px;
        z-index: 2;
        img {
          max-height: 100%;
          max-width: 100%;
        }
      }
    }
    .category-name {
      position: absolute;
      pointer-events: none;
      width: 100%;
      top: auto;
      left: auto;
      transform: none;
      .title {
        font-size: 14px;
      }
    }
    .overlay {
      display: none;
    }
  }
}
