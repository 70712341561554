.PromotionsContainer {
  .promotions-grid .Promotion {
    background: var(--white);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    .promotion-content-wrapper {
      padding: 30px;
    }
  }
}

.PromotionDetailContainer {
  .promotion-banner .banner-content {
    background: transparent;
  }
}
