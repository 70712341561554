.Checkbox {
  position: relative;

  .error-text {
    z-index: 10;
    position: absolute;
    left: 10px;
    transform: translate(0, -20%);
    margin: 0;
    background: var(--dark-gray);
    color: var(--white);
    border-radius: 2px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    font-size: 0.8em;
    padding: 6px;
  }
}
