.Popup {
  .Popup__content {
    .Popup__header {
      color: var(--white);
    }
  }
  
  .Popup__children {
    .RealityCheckContainer {
      .actions {
        .button {
          &:last-child {
            width: 100%;
            margin: 15px 0;
          }
        }
      }
    }
  }
}

.SuccessfulDepositContainer {
  img {
    width: 15%;
  }
}

.LimitConfirmation {
  color: #fff;
}
