.BetslipTab {
  box-shadow: -1px 0 6px 0 rgba(0, 0, 0, 0.3);
  width: auto;
  left: 50px;
  right: 50px;
  height: 40px;
  border-bottom: none;
  color: #fff;
  font-weight: bold;
  transition: 0.2s all;
  bottom: 0;
  &.active {
    transition: 0.2s all;
    bottom: 70px;
    @supports (bottom: env(safe-area-inset-bottom)) {
      bottom: calc(70px + env(safe-area-inset-bottom, 10px));
    }

    &.hide {
      transition: 0.2s all;
      bottom: 10px;
      @supports (bottom: env(safe-area-inset-bottom)) {
        bottom: calc(10px + env(safe-area-inset-bottom, 10px));
      }
    }
  }
}
