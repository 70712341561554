/* Put all brand specific css and logos/backgrounds/icons in this skin folder. */

body {
  color: #1f1f1f;
  background-color: #f7f7f7;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}

.main-content-container {
  color: #1f1f1f;
  margin-top: 89px;
}

@media (max-width: 992px) {

.main-content-container {
    margin-top: calc(88px + 55px)
}
  }

.jurisdiction-sga.is-authenticated
  .BasePage:not(.IndexContainer)
  .main-content-container {
  margin-top: calc(89px + 30px);
}

@media (max-width: 992px) {

.jurisdiction-sga.is-authenticated
  .BasePage:not(.IndexContainer)
  .main-content-container {
    margin-top: calc(88px + 30px)
}
  }

.h1,
h1 {
  font-size: 2.5em;
  margin: 0 0 0.67em 0;
}

.h2,
h2 {
  font-size: 2em;
  margin: 0 0 0.83em 0;
}

.h3,
h3 {
  font-size: 1.75em;
  margin: 0 0 1em 0;
}

.h4,
h4 {
  font-size: 1.5em;
  margin: 0 0 1.33em 0;
}

.h5,
h5 {
  font-size: 1.25em;
  margin: 0 0 1.67em 0;
}

.h6,
h6 {
  font-size: 1em;
  margin: 0 0 2.33em 0;
}

.button,
button,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 900;
}

a {
  text-decoration: underline;
}

@media (max-width: 992px) {
  .h1,
  h1 {
    font-size: 2em;
  }
  .h2,
  h2 {
    font-size: 1.5em;
  }
  .h3,
  h3 {
    font-size: 1.17em;
  }
  .h4,
  h4 {
    font-size: 1em;
  }
  .h5,
  h5 {
    font-size: 0.83em;
  }
  .h6,
  h6 {
    font-size: 0.67em;
  }

  .SettingsTab {
    bottom: 55px;
  }
}

@media (max-width: 992px) {
    body:not(.is-authenticated) .main-content-container {
      margin-top: calc(
        88px + 55px
      );
    }
}

@media (min-width: 993px) {
  #fc_frame:not(.fc-open) {
    display: initial;
  }
    .sidebar-is-open #fc_frame:not(.fc-open) {
      display: none;
    }
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('OpenSans-Light'),
    url('/skin/fonts/OpenSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: local('OpenSans-LightItalic'),
    url('/skin/fonts/OpenSans-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('OpenSans-Regular'),
    url('/skin/fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('OpenSans-Italic'),
    url('/skin/fonts/OpenSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('OpenSans-Bold'),
    url('/skin/fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: local('OpenSans-BoldItalic'),
    url('/skin/fonts/OpenSans-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 900;
  src: local('OpenSans-ExtraBold'),
    url('/skin/fonts/OpenSans-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'icomoon';
  src:  url('/skin/icomoon/icomoon.eot?8pnd1o');
  src:  url('/skin/icomoon/icomoon.eot?8pnd1o#iefix') format('embedded-opentype'),
    url('/skin/icomoon/icomoon.ttf?8pnd1o') format('truetype'),
    url('/skin/icomoon/icomoon.woff?8pnd1o') format('woff'),
    url('/skin/icomoon/icomoon.svg?8pnd1o#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-play-solid:before {
  content: "\e800";
}

.header-wrapper {
  background-color: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.header-wrapper .Header__background.Header__background--visible {
      background-color: #ffffff;
    }

.header-wrapper .Header__support {
      display: none;
    }

.header-wrapper .Header__hamburger {
      margin-right: 10px;
    }

.header-wrapper .Header__hamburger .hamburger-icon img {
        height: 20px;
      }

@media (max-width: 992px) {

.header-wrapper .Header__hamburger {
        display: none
    }
      }

.header-wrapper .Header__topnav {
      margin: 0 auto;
      font-weight: bold;
    }

.header-wrapper .Header__topnav .top-nav-menu-item {
        margin: 0 15px;
        padding: 0;
      }

.header-wrapper .Header__topnav .top-nav-menu-item.active {
          position: relative;
        }

.header-wrapper .Header__topnav .top-nav-menu-item.active:after {
            content: '';
            position: absolute;
            height: 3px;
            background: #42cc5f;
            bottom: -4px;
            width: 100%;
            left: 0;
          }

.header-wrapper .Header__profile {
      position: relative;
    }

.header-wrapper .Header__profile .notifications-badge--right[data-notifications]:not(
          [data-notifications='']
        ):not([data-notifications='0'])::after {
        position: absolute;
        top: 0;
        left: 26px;
        transform: translate(-50%, -50%);
      }

.header-wrapper .Header__logo img {
      height: 30px;
      width: auto;
    }

.header-wrapper .Header__actions .button {
        font-size: 1em !important;
      }

@media (min-width: 993px) {

.header-wrapper .Header__actions .button {
          padding: 0 3em;
          height: 50px;
          line-height: 50px
      }
          .header-wrapper .Header__actions .button span {
            font-size: 1.5em !important;
          }
        }

@media (max-width: 992px) {
      body:not(.is-authenticated) .header-wrapper .Header {
        flex-wrap: wrap;
        height: auto;
        padding: 0;
      }

        body:not(.is-authenticated) .header-wrapper .Header__logo {
          margin: 10px 0 10px 10px;
        }

        body:not(.is-authenticated) .header-wrapper .Header__actions {
          width: 100vw;
        }

          body:not(.is-authenticated) .header-wrapper .Header__actions .MembershipActions {
            width: 100%;
          }

            body:not(.is-authenticated) .header-wrapper .Header__actions .MembershipActions > a {
              display: block;
              margin: 0;
              width: 50%;
              text-align: center;
            }

        body:not(.is-authenticated) .header-wrapper .Header__background {
          height: auto;
        }
}

@media (max-width: 350px) {
      .header-wrapper .Header__logo img {
        height: 20px;
      }
}

.Footer {
  background-color: #000000;
}

.Footer .footer-logo {
    display: none;
  }

.Footer .licence div {
      display: flex !important;
    }

.Footer .licence div p a {
        display: flex;
        flex-flow: column;
        text-decoration: none;
      }

.Footer .licence div p a span {
          font-size: 12px;
        }

.Footer .licence div p a svg {
          height: 40px;
          margin-right: 0;
        }

.Footer .topSection {
    background-color: #000000;
  }

@media (min-width: 993px) {

.Footer .topSection .container {
        flex-direction: row
    }
      }

button,
.button {
  font-weight: 900;
  text-transform: uppercase;
}

button[disabled], .button[disabled] {
    background: #737373;
  }

button[disabled]:hover, .button[disabled]:hover {
      background: #737373;
    }

button.tertiary,
  button .tertiary-button,
  .button.tertiary,
  .button .tertiary-button {
    color: #ffffff;
    background: #3d3d3d;
  }

button.tertiary:hover, button .tertiary-button:hover, .button.tertiary:hover, .button .tertiary-button:hover {
      background: #383838;
    }

.Banners .slick-slider .slick-list .slick-track .slick-slide {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

.Banners .slick-slider .slick-list .slick-track .slick-slide .button {
      margin: 25px 0;
      font-size: 1.2rem;
    }

.Banners .slick-slider .slick-list .slick-track .slick-slide .content {
      width: 100%;
      max-width: 1900px;
      padding: 0 20px;
      position: relative;
      margin-bottom: 20px;

      /* reset styles from tg-base */
      left: auto !important;
      top: auto !important;
      bottom: auto !important;
    }

.Banners .slick-slider .slick-list .slick-track .slick-slide .disclaimer {
      left: 20px;
      bottom: 20px;
      font-size: 12px;
    }

.Banners .slick-slider .slick-arrow.slick-next,
    .Banners .slick-slider .slick-arrow.slick-prev {
      display: none !important;
    }

.Banners .slick-dots {
    bottom: 0;
  }

.Banners .slick-dots li button::before {
        color: #ffffff;
        font-size: 10px;
        opacity: 1;
      }

.Banners .slick-dots li.slick-active button::before {
          color: #42cc5f;
          font-size: 10px;
          opacity: 1;
        }

.jurisdiction-sga.is-authenticated .Banners {
  margin-top: calc(89px + 30px);
}

@media (max-width: 992px) {
  .Banners {
    min-height: 60vh !important;
    max-height: 60vh !important;
    margin-top: calc(88px + 55px);
  }
      .Banners .slick-slider .slick-list .slick-track .slick-slide .content {
        padding: 0 10px;
      }

  .jurisdiction-sga.is-authenticated .Banners {
    margin-top: calc(88px + 30px);
  }
}

.IndexContainerPnp .InstallApp {
  margin-top: 0;
}

.InstallApp {
  color: #ffffff;
  text-shadow: 0 5px 5px rgb(0 0 0 / 30%);
}

.InstallAppContainer .Popup__header {
    text-shadow: 0 5px 5px rgb(0 0 0 / 30%);
  }

body[data-install-app='true'].page-casino .InstallApp, body[data-install-app='true'].page-live-casino .InstallApp {
    margin-top: 89px;
  }

@media (max-width: 992px) {
    body[data-install-app='true'].page-casino .InstallApp, body[data-install-app='true'].page-live-casino .InstallApp {
      margin-top: calc(
        88px + 55px
      );
    }

    body[data-install-app='true'].page-casino .Banners, body[data-install-app='true'].page-live-casino .Banners {
      margin-top: 0;
      min-height: calc(60vh - 60px) !important;
      max-height: calc(60vh - 60px) !important;
    }
}

.column-wrapper {
  padding: 0;
  background-color: #efefef;
}

.column-wrapper .columns {
    max-width: initial;
  }

.column-wrapper .columns .column {
      overflow: hidden;
      position: relative;
      flex: 1 1;
    }

.column-wrapper .columns .column div {
        text-align: center;
        color: #1f1f1f;
        padding: 10px 0;
      }

.column-wrapper .columns .column div p {
          line-height: 12px;
        }

.column-wrapper .columns .column div > * {
          margin: 0;
        }

.column-wrapper .columns .column:nth-child(2):before {
          left: -20px;
        }

.column-wrapper .columns .column:nth-child(2):after {
          right: -20px;
        }

.column-wrapper .columns .column:nth-child(2):after,
        .column-wrapper .columns .column:nth-child(2):before {
          content: '';
          height: 8px;
          width: 140px;
          background: #42cc5f;
          position: absolute;
          transform: rotate(-45deg);
        }

.GridLayout .grid-header {
    justify-content: flex-start;
    padding: 10px 0;
  }

.GridLayout .grid-header .grid-title {
      margin-right: 10px;
    }

.GridLayout .grid-header .show-more a {
      background-color: #26b74f;
      padding: 5px;
    }

.GridLayout.sport .item > div:not(.Game) .content {
        margin: 0;
        padding: 0 0 10px 10px;
        position: absolute;
        bottom: 0;
        left: 0;
      }

.GridLayout.sport .item > div:not(.Game) a:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(31, 31, 31, 0), rgba(7, 7, 7, 0.8));
      }

.IndexPromotion {
  background-color: #efefef;
  display: flex;
  align-items: flex-start;
}

.IndexPromotion .content {
    max-width: 65%;
  }

.IndexPromotion .content p {
      margin: 0;
    }

.IndexPromotion .content h1:first-child, .IndexPromotion .content h2:first-child, .IndexPromotion .content h3:first-child, .IndexPromotion .content h4:first-child, .IndexPromotion .content h5:first-child, .IndexPromotion .content h6:first-child {
        position: relative;
        display: inline;
        z-index: 0;
      }

.IndexPromotion .content h1:first-child:after, .IndexPromotion .content h2:first-child:after, .IndexPromotion .content h3:first-child:after, .IndexPromotion .content h4:first-child:after, .IndexPromotion .content h5:first-child:after, .IndexPromotion .content h6:first-child:after {
          content: '';
          position: absolute;
          height: 8px;
          width: 100%;
          background: #42cc5f;
          bottom: 0;
          left: 0;
          z-index: -1;
        }

.IndexPromotion .content h1:nth-child(2), .IndexPromotion .content h2:nth-child(2), .IndexPromotion .content h3:nth-child(2), .IndexPromotion .content h4:nth-child(2), .IndexPromotion .content h5:nth-child(2), .IndexPromotion .content h6:nth-child(2) {
        margin: 5px 0 0 0;
      }

.Game__favorite-heart {
    z-index: 10;
  }

.Game .jackpot {
    bottom: unset;
    color: #ffffff;
  }

.Game .thumbnail {
    z-index: -1;
  }

.Game .thumbnail:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(rgba(31, 31, 31, 0), rgba(7, 7, 7, 0.8));
    }

.Game .game-info {
    color: #ffffff;
    line-height: initial;
    margin: 0;
    padding: 0 0 10px 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
  }

.Game .badges {
    bottom: initial;
    top: 5px;
  }

@media (min-width: 992px) {
      .Game:hover .favorite-heart {
        display: initial;
      }

      .Game:hover .jackpot,
      .Game:hover .ribbon,
      .Game:hover .badges {
        display: none;
      }

      .Game:hover:before {
        display: block;
      }
    }

.Game:before {
    pointer-events: none;
    color: #ffffff;
    font-family: 'icomoon';
    font-size: 32px;
    content: '\e800';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 60px;
    height: 60px;
    line-height: 60px;
    display: none;
    text-indent: 18px;
    border-radius: 50%;
    /* Override tg-base */
    background: #42cc5f !important;
  }

.GameGrid--extra-margin-on-mobile .Game {
  margin: 0;
}

.StickyFooter-mobile {
  top: 88px;
  z-index: 0;
  background-color: #e5e5e5;
  box-shadow: -1px 0 6px 0 rgba(0, 0, 0, 0.3);
  width: 100%;
  overflow: hidden;
  transition: 0.2s all;
  margin-left: 0;
  margin-right: 0;
}

.StickyFooter-mobile.hide {
    transition: 0.2s all;
    transform: translateY(-100%);
  }

.StickyFooter-mobile a {
    border-left: solid 1px #efefef;
    font-size: 10px;
  }

.StickyFooter-mobile a.active {
      background-color: #26b74f;
      color: #ffffff;
    }

.StickyFooter-mobile a.active img {
        filter: invert(100%);
      }

.StickyFooter-mobile .hamburger-icon .notifications-badge, .StickyFooter-mobile .exit-sidebar-icon .notifications-badge {
      display: none;
    }

.StickyFooter-mobile .hamburger-icon img, .StickyFooter-mobile .exit-sidebar-icon img {
      width: 18px;
    }

.StickyFooter-mobile .profile-icon {
    position: relative;
  }

.StickyFooter-mobile .profile-icon .notifications-badge--right[data-notifications]:not(
        [data-notifications='']
      ):not([data-notifications='0'])::after {
      position: absolute;
      top: 2px;
      left: 55%;
      content: attr(data-notifications);
    }

.StickyFooter-mobile img {
    height: 18px;
    width: 18px;
  }

.OffCanvas.GlobalNavContainer .canvas-content {
    background: transparent;
    padding: 0;
  }

.OffCanvas.GlobalNavContainer .GlobalNavContainer__link--active {
      background: #e5e5e5;
    }

.OffCanvas.GlobalNavContainer .GlobalNavContainer__link img {
      height: 18px;
      width: auto;
      margin-right: 5px;
    }

.OffCanvas.GlobalNavContainer .section-wrapper {
    padding: 10px 0;
    background: #e5e5e5;
  }

.OffCanvas.GlobalNavContainer .support-button-wrapper {
    background-color: #e5e5e5;
    border-top: 1px solid #737373;
  }

@media (max-width: 992px) {
    .OffCanvas.GlobalNavContainer .GlobalNavContainer__button {
      margin: 0 15px;
      width: calc(100% - 30px);
    }

      .OffCanvas.GlobalNavContainer .GlobalNavContainer__button--support {
        margin: 20px 15px;
      }

    .OffCanvas.GlobalNavContainer .GlobalNavContainer__link {
      padding: 7px 0 7px 15px;
    }
}

.CasinoCategoryMenu {
  background-color: #efefef;
  margin: 0;
  max-width: initial;
  padding-left: 10px;
}

.CasinoCategoryMenu .casino-search .search-input {
      max-width: calc(100% - 45px);
      width: 500px;
    }

.CasinoCategoryMenu .casino-search .search-input .Input {
        margin: 0;
      }

.CasinoCategoryMenu .casino-search .search-input .Input input {
          /* overriding color from tg-base */
          margin-left: 0;
          color: #1f1f1f;
        }

.CasinoCategoryMenu .casino-search .search-input .Input input::placeholder {
            color: #737373;
          }

.CasinoCategoryMenu .casino-search .search-input .Input__status-line {
          background: none;
        }

.CasinoCategoryMenu .categories-menu {
    font-weight: bold;
  }

.CasinoCategoryMenu .categories-menu .category-item {
      height: 100%;
      margin: 0;
      border-radius: 0;
      padding: 0 15px;
      line-height: 50px;
    }

.CasinoCategoryMenu .categories-menu .category-item:first-child {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }

.CasinoCategoryMenu .categories-menu .category-item:not(:first-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }

.CasinoCategoryMenu .categories-menu .category-item.active {
        background-color: #ffffff;
        position: relative;
      }

.CasinoCategoryMenu .categories-menu .category-item.active:after {
          content: '';
          position: absolute;
          height: 3px;
          background: #42cc5f;
          bottom: 0;
          width: 100%;
          left: 0;
        }

.CasinoContainer .GameGridList {
    padding: 30px 0;
  }

.CasinoContainer .GameGridList .GameGrid .grid-header {
        align-items: center;
        justify-content: space-between;
        margin: 0 auto 15px auto;
        padding: 0;
        max-width: calc(94vw - 6px);
      }

.CasinoContainer .GameGridList .GameGrid .grid-header a {
          background-color: #26b74f;
          color: #ffffff;
          font-weight: bold;
          font-size: 0.6em;
          padding: 5px 7px;
          margin: 0;
          text-transform: uppercase;
        }

.CasinoContainer .GameGridList .GameGrid .grid-header a:hover {
            background-color: #23a648;
            opacity: 1;
          }

.CasinoContainer .GameGridList .GameGrid:not(.search-grid) {
        margin-bottom: 30px;
      }

.CasinoContainer .GameGridList .GameGrid:not(.search-grid) .slider .slide-item .last-slide a {
                background: #26b74f;
                color: #ffffff;
              }

.CasinoContainer .GameGridList .GameGrid:not(.search-grid) .arrow i {
            display: none;
          }

.CasinoContainer .GameGridList .GameGrid:not(.search-grid) .arrow--next {
            background: rgba(35, 35, 35, 0.8);
          }

.CasinoContainer .GameGridList .GameGrid:not(.search-grid) .arrow--next:before {
              display: block;
              content: '';
              position: absolute;
              height: 21px;
              width: 12px;
              background-image: url('../images/back-arrow.svg');
              transform: rotateY(180deg);
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }

.CasinoContainer .GameGridList .GameGrid:not(.search-grid) .arrow--prev {
            background: rgba(35, 35, 35, 0.8);
          }

.CasinoContainer .GameGridList .GameGrid:not(.search-grid) .arrow--prev:before {
              display: block;
              content: '';
              position: absolute;
              height: 21px;
              width: 12px;
              background-image: url('../images/back-arrow.svg');
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }

@media (max-width: 993px) {
    .CasinoContainer .GameGridList {
      padding: 15px 0;
    }
        .CasinoContainer .GameGridList .GameGrid .grid-header {
          margin: 0 10px;
          max-width: 100vw;
        }

        .CasinoContainer .GameGridList .GameGrid:not(.search-grid) {
          margin-bottom: 15px;
        }
}

.casino-promotion {
  background-color: #efefef;
  margin: 0 0 30px 0;
}

.casino-promotion .summary {
    line-height: 2.5em;
  }

.casino-promotion .casino-promotion-bg::before,
    .casino-promotion .casino-promotion-bg::after {
      display: none;
    }

@media (max-width: 992px) {
  .casino-promotion {
    margin: 0 0 15px 0;
  }

    .casino-promotion .container-max-width {
      padding: 15px 10px;
    }
    .casino-promotion .actions {
      margin: 0;
    }
}

.cashier-accordion .AccordionItem .payment-method-item-content .account-selection {
        background: #ffffff;
        color: inherit;
      }

.cashier-accordion .AccordionItem .payment-method-item-content .account-selection:hover {
          background: #ffffff;
        }

.cashier-accordion .AccordionItem .payment-method-item-content .amount-selector button {
          background: #737373;
          color: #ffffff;
        }

.cashier-accordion .AccordionItem .payment-method-item-content .custom-amount-and-submit button {
          background: #42cc5f;
        }

.cashier-accordion .AccordionItem .payment-method-item-content .notice.level-warning {
        background: #fff0bc;
        border-color: #ffd749;
        color: #000000;
      }

.cashier-accordion .payment-result-wrapper {
    background: #e5e5e5;
  }

.cashier-accordion .payment-result-wrapper .payment-result-content .payment-result-balance {
        color: #42cc5f;
      }

.cashier-accordion .fill-in-steps {
    background: #e5e5e5;
  }

@media (max-width: 370px) {
        .cashier-accordion .AccordionItem__header .payment-method-item-header .provider-logo {
          width: 65px !important;
          margin-right: 5px;
        }
}

.bonus-footer {
  height: 55px;
}

.bonus-footer .bonus-background {
    display: none;
  }

@media (max-width: 992px) {

.bonus-footer {
    bottom: 55px
}
  }

.BonusStep a {
    text-decoration: underline;
  }

.AccordionWidget .AccordionItem__header {
      background-color: #dcdcdc;
    }

.AccordionWidget .AccordionItem__header:hover {
        background-color: #dcdcdc;
      }

.AccordionWidget .AccordionItem__header .ResponsibleGamingWidget__limit-header-title,
      .AccordionWidget .AccordionItem__header .title-text {
        font-weight: 900;
      }

.AccordionWidget .AccordionItem__header__expand-icon {
        color: #737373;
      }

.AccordionWidget .AccordionItem__content {
      background-color: #dcdcdc;
    }

.AccordionWidget .AccordionItem__content div:is(.no-list-data) + .navigation {
          display: none;
        }

.AccordionWidget .AccordionItem__content div:is(.tg-list) .navigation {
          display: flex;
          justify-content: space-between;
        }

.AccordionWidget .AccordionItem__content .table {
        background-color: #dcdcdc;
      }

.AccordionWidget .AccordionItem__content .detail {
        background: #e5e5e5 !important;
      }

.AccordionWidget .AccordionItem__content .detail:before {
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid #e5e5e5;
        }

.AccordionWidget .pagination {
    display: flex;
  }

.AccordionWidget .pagination li.disabled a {
      color: #737373;
    }

.tg-input,
.tg-select,
.tg-phonenumber-input,
.tg-password-input,
.tg-birthdate-input {
  position: relative;
}

.tg-input .Input,
  .tg-input .Select,
  .tg-select .Input,
  .tg-select .Select,
  .tg-phonenumber-input .Input,
  .tg-phonenumber-input .Select,
  .tg-password-input .Input,
  .tg-password-input .Select,
  .tg-birthdate-input .Input,
  .tg-birthdate-input .Select {
    margin-bottom: 20px;
    background: #ffffff;
    position: relative;
  }

.tg-input .Input__leading-lane i.icon:not(.icon-check-circle):before, .tg-input .Input__trailing-lane i.icon:not(.icon-check-circle):before, .tg-input .Select__leading-lane i.icon:not(.icon-check-circle):before, .tg-input .Select__trailing-lane i.icon:not(.icon-check-circle):before, .tg-select .Input__leading-lane i.icon:not(.icon-check-circle):before, .tg-select .Input__trailing-lane i.icon:not(.icon-check-circle):before, .tg-select .Select__leading-lane i.icon:not(.icon-check-circle):before, .tg-select .Select__trailing-lane i.icon:not(.icon-check-circle):before, .tg-phonenumber-input .Input__leading-lane i.icon:not(.icon-check-circle):before, .tg-phonenumber-input .Input__trailing-lane i.icon:not(.icon-check-circle):before, .tg-phonenumber-input .Select__leading-lane i.icon:not(.icon-check-circle):before, .tg-phonenumber-input .Select__trailing-lane i.icon:not(.icon-check-circle):before, .tg-password-input .Input__leading-lane i.icon:not(.icon-check-circle):before, .tg-password-input .Input__trailing-lane i.icon:not(.icon-check-circle):before, .tg-password-input .Select__leading-lane i.icon:not(.icon-check-circle):before, .tg-password-input .Select__trailing-lane i.icon:not(.icon-check-circle):before, .tg-birthdate-input .Input__leading-lane i.icon:not(.icon-check-circle):before, .tg-birthdate-input .Input__trailing-lane i.icon:not(.icon-check-circle):before, .tg-birthdate-input .Select__leading-lane i.icon:not(.icon-check-circle):before, .tg-birthdate-input .Select__trailing-lane i.icon:not(.icon-check-circle):before {
        color: #1f1f1f;
      }

.tg-input .Select .Input__trailing-lane i.icon-caret-down:before, .tg-select .Select .Input__trailing-lane i.icon-caret-down:before, .tg-phonenumber-input .Select .Input__trailing-lane i.icon-caret-down:before, .tg-password-input .Select .Input__trailing-lane i.icon-caret-down:before, .tg-birthdate-input .Select .Input__trailing-lane i.icon-caret-down:before {
          content: '\e920';
        }

.tg-input__status-text, .tg-select__status-text, .tg-phonenumber-input__status-text, .tg-password-input__status-text, .tg-birthdate-input__status-text {
    min-height: 0;
  }

.tg-input--status-failure .tg-input__status-text, .tg-select--status-failure .tg-select__status-text, .tg-phonenumber-input--status-failure .tg-phonenumber-input__status-text, .tg-password-input--status-failure .tg-password-input__status-text, .tg-birthdate-input--status-failure .tg-birthdate-input__status-text {
    width: auto;
    z-index: 10;
    position: absolute;
    left: 10px;
    bottom: 0;
    transform: translate(0, 70%);
    background: #737373;
    color: #ffffff;
    border-radius: 2px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    font-size: 0.8em;
    padding: 6px;
  }

.SourceOfWealthContainer .occupation .tg-input input {
  border: 1px solid #000000;
  border-radius: 8px;
}

.OffCanvas .canvas-content {
    background: #efefef;
  }

.OffCanvas .canvas-content .canvas-header span {
        display: none;
      }

.OffCanvas .canvas-content .canvas-header .canvas-close img, .OffCanvas .canvas-content .canvas-header .canvas-back img {
          height: 28px;
          width: auto;
        }

@media (max-width: 992px) {
  .OffCanvas {
    height: 100%;
  }
    .OffCanvas .canvas-content {
      padding: 20px 20px 100px;
    }
}

/* Dashboard index */

.canvas-content .notifications-badge.notifications-badge--right:after {
    color: #ffffff;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 45px;
  }

.canvas-content .section.membership-buttons {
      flex-wrap: nowrap;
    }

.canvas-content .section.membership-buttons .button {
        width: 100%;
        text-align: center;
      }

.canvas-content .section.initials {
      color: #ffffff;
    }

.canvas-content .section.products {
      order: 3;
    }

.canvas-content .section.payment-buttons,
    .canvas-content .section.membership-buttons {
      order: 2;
    }

.canvas-content .section.payment-buttons .button:first-child, .canvas-content .section.membership-buttons .button:first-child {
        margin: 0 5px 0 0;
      }

.canvas-content .section.payment-buttons .button:last-child, .canvas-content .section.membership-buttons .button:last-child {
        margin: 0 0 0 5px;
      }

.canvas-content .section.profile-links {
      order: 4;
      margin-top: 20px;
    }

.canvas-content .section.support {
      order: 5;
      padding: 20px 0;
    }

.canvas-content .section.footer {
      order: 6;
    }

.canvas-content .section.footer .button {
        display: none;
      }

.canvas-content .section.profile-links,
    .canvas-content .section.products {
      flex-direction: column;
    }

.canvas-content .section.profile-links .ItemWithIconAndText, .canvas-content .section.products .ItemWithIconAndText {
        position: relative;
        margin: 1px;
        height: 68px;
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        background-color: #dcdcdc;
        padding: 15px;
      }

.canvas-content .section.profile-links .ItemWithIconAndText .text, .canvas-content .section.products .ItemWithIconAndText .text {
          margin: 0;
          font-weight: bold;
        }

.canvas-content .section.profile-links .ItemWithIconAndText .icon, .canvas-content .section.products .ItemWithIconAndText .icon {
          width: 35px;
          height: 35px;
          margin-right: 10px;
        }

.canvas-content .section.profile-links .ItemWithIconAndText:after, .canvas-content .section.products .ItemWithIconAndText:after {
          display: block;
          content: '';
          position: absolute;
          height: 21px;
          width: 12px;
          background-image: url('../images/back-arrow.svg');
          transform: rotateY(180deg);
          top: 0;
          bottom: 0;
          right: 15px;
          margin: auto;
        }

.canvas-content .section.balance {
      order: 1;
    }

.canvas-content .section.balance div:first-child span {
          color: #42cc5f;
        }

.Dashboard .balance .refresh-button,
    .Dashboard .balance .refresh-button.loading {
      /* not displayed by default */
      display: initial;
    }

.Dashboard .balance .refresh-button i, .Dashboard .balance .refresh-button.loading i {
        color: #42cc5f;
      }

.Dashboard .navigation {
    order: 6;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
  }

.Dashboard .navigation a {
      text-decoration: none;
      padding: 5px 0;
    }

.Dashboard .navigation a:hover {
        text-decoration: underline;
      }

@media (max-width: 992px) {

.Dashboard .navigation {
      display: flex
  }
    }

.ResponsibleGamingWidget .Select, .ResponsibleGamingWidget .Input {
    margin: 0;
  }

.ResponsibleGamingWidget__timespan-limit-selector {
    margin: 8px 0;
  }

.ResponsibleGamingWidget__timespan-limit-selector .button {
      padding: 0;
      background: #42cc5f;
    }

body {
  --gameplay-header-bgcolor: #ffffff;
  --gameplay-gamedetails-bgcolor: #ffffff;
  --gameplay-loader-bgcolor: none;

  --gameplay-popunder-overlay-bgcolor: rgba(35, 35, 35, 0.8);

  --gameplay-friendly-sign-in-message-height: 0px;
}

body.is-desktop,
  body.mobile {
    --gameplay-header-height: 55px;
  }

body.is-desktop .PlayContainer .GamePlay .GamePlayHeader__name, body.mobile .PlayContainer .GamePlay .GamePlayHeader__name {
            display: none;
          }

body.is-desktop .PlayContainer .GamePlay .GamePlayHeader__menu.button.icon, body.mobile .PlayContainer .GamePlay .GamePlayHeader__menu.button.icon {
            background-color: #ffffff;
          }

body.is-desktop .PlayContainer .GamePlay .GamePlayHeader__menu.button.icon i, body.mobile .PlayContainer .GamePlay .GamePlayHeader__menu.button.icon i {
              color: #42cc5f;
            }

body.is-desktop .PlayContainer .GamePlay .GamePlayHeader__menu-close.button.icon i, body.mobile .PlayContainer .GamePlay .GamePlayHeader__menu-close.button.icon i {
              color: #23a649;
            }

body.is-desktop .PlayContainer .GamePlay .GamePlayHeader__back span, body.mobile .PlayContainer .GamePlay .GamePlayHeader__back span {
              display: none;
            }

body.is-desktop .PlayContainer .GamePlay .GamePlayHeader__hamburger, body.mobile .PlayContainer .GamePlay .GamePlayHeader__hamburger {
            border-radius: 8px;
            width: 35px;
            height: 35px;
            padding: 0;
          }

body.is-desktop .PlayContainer .GamePlay .GamePlayHeader__hamburger .hamburger-icon, body.mobile .PlayContainer .GamePlay .GamePlayHeader__hamburger .hamburger-icon {
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

body.is-desktop .PlayContainer .GamePlay .GamePlayHeader__hamburger .hamburger-icon img, body.mobile .PlayContainer .GamePlay .GamePlayHeader__hamburger .hamburger-icon img {
                height: 15px;
              }

body.is-desktop .PlayContainer .GamePlay .GamePlayHeader .MembershipActions, body.mobile .PlayContainer .GamePlay .GamePlayHeader .MembershipActions {
            height: 35px;
          }

body.is-desktop .PlayContainer .GamePlay .GamePlayHeader .MembershipActions .button, body.mobile .PlayContainer .GamePlay .GamePlayHeader .MembershipActions .button {
              height: 100%;
              line-height: 35px;
            }

@media (max-width: 992px) {
          body.is-desktop .PlayContainer .GamePlay .PopUnder, body.mobile .PlayContainer .GamePlay .PopUnder {
            top: 0;
          }
              body.is-desktop .PlayContainer .GamePlay .PopUnder--closed .PopUnder__content, body.mobile .PlayContainer .GamePlay .PopUnder--closed .PopUnder__content {
                transform: translateY(-100%);
              }
            body.is-desktop .PlayContainer .GamePlay .PopUnder__content, body.mobile .PlayContainer .GamePlay .PopUnder__content {
              top: calc(
                var(--gameplay-header-height) + var(--gameplay-topbar-height) +
                  var(--gameplay-friendly-sign-in-message-height)
              );
            }
        }

.GamePlay {
  color: #42cc5f;
}

.GamePlay .GameDetails__categories-nav > div:first-child {
      width: 100%;
    }

.GamePlay .GameDetails__quick-actions .button.icon, .GamePlay .GamePlayHeader__back .button.icon, .GamePlay .GameDetails__name-and-fav .button.icon {
        background-color: transparent;
        color: #000000;
        width: 35px;
        height: 35px;
        padding: 0;
      }

.GamePlay .GameDetails__quick-actions .button.icon i, .GamePlay .GamePlayHeader__back .button.icon i, .GamePlay .GameDetails__name-and-fav .button.icon i {
          width: 100%;
          height: 100%;
          color: #42cc5f;
        }

.GamePlay .GamePlayHeader__menu.button.icon {
      background-color: #ffffff;
    }

.GamePlay .GamePlayHeader__menu.button.icon i {
        color: #42cc5f;
      }

.GamePlay .GamePlayHeader__menu-close.button.icon i {
        color: #23a649;
      }

.GamePlay .GameFrame__footer {
      display: none;
    }

.GamePlay .GameDetails__actions {
      transform: translate(0);
      margin: 10px 0;
    }

.GamePlay .GameDetails__actions .button {
        margin: 5px;
      }

.GamePlay .GameDetails__categories-nav .CasinoCategoryMenu {
        display: flex;
        align-items: center;
        background-color: transparent;
      }

@media (max-width: 992px) {

.GamePlay .GameDetails__categories-nav .CasinoCategoryMenu:before {
            background: linear-gradient(
              to right,
              rgba(255,255,255, 0) 0%,
              #ffffff 80%
            )
        }
          }

.GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .casino-search {
          transition: none;
        }

.GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .casino-search .search-input {
            margin-left: 10px;
            border-radius: 8px;
          }

.GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .casino-search:not(.active) {
            width: 35px;
            height: 35px;
            border-radius: 8px;
          }

.GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .casino-search .icon.icon-times {
            width: 35px;
            height: 35px;
            border-radius: 8px;
          }

.GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .categories-menu .category-item {
            border-radius: 8px;
            color: #000000;
            border: none;
          }

.GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .categories-menu .category-item.active {
              color: #000000;
            }

.GamePlay .GameDetails__categories-nav .CasinoCategoryMenu .categories-menu .category-item:hover {
              opacity: 1;
            }

.GamePlay .GameDetails__category .slider .slide-item .last-slide a {
              color: #ffffff;
            }

.GamePlay .QuickDeposit .Select {
      margin: 0;
    }

.GamePlay .QuickDeposit .Select select {
        border: none;
        padding: 0;
      }

.GamePlay .QuickDeposit .Input__trailing-lane i.icon {
      right: 0;
      font-size: 12px;
      width: 12px;
      height: 12px;
      margin: 0;
    }

.GamePlay .QuickDeposit .Input__trailing-lane i.icon:before {
        color: #42cc5f;
      }

.GamePlay .QuickDeposit .QuickAmounts * {
        box-shadow: none;
      }

.GamePlay .QuickDeposit .QuickAmounts .button i {
          font-size: 1em;
        }

.GamePlay .QuickDeposit .QuickAmounts .amount-buttons >:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }

.GamePlay .QuickDeposit .QuickAmounts .custom-amount {
        border-radius: 8px;
      }

.GamePlay .QuickDeposit .QuickAmounts .custom-amount .Input {
          margin: 0;
        }

.GamePlay .QuickDeposit .QuickAmounts .custom-amount .Input__status-line {
            background: none;
          }

.GamePlay .QuickDeposit .QuickAmounts .action {
        background: #26b74f;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

.GamePlay .QuickDeposit .QuickAmounts .action:hover {
          background: #23a648;
        }

@media (max-width: 992px) {
    .GamePlay .PopUnder {
      top: 0;
    }
        .GamePlay .PopUnder--closed .PopUnder__content {
          transform: translateY(-100%);
        }
      .GamePlay .PopUnder__content {
        top: calc(
          var(--gameplay-header-height) + var(--gameplay-topbar-height) +
            var(--gameplay-friendly-sign-in-message-height)
        );
      }
  }

.ContentContainer .content-container .content > div {
    background: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 15px;
    margin: auto 10px;
    width: 100%;
  }

@media (max-width: 992px) {
    .ContentContainer pre code {
      white-space: pre-line;
      font-size: 0.875em;
    }
  }

.ContentContainer .NavList ul {
      background-color: #ffffff;
    }

.ContentContainer .NavList ul a {
        color: #1f1f1f;
        font-weight: 900;
      }

.ContentContainer .NavList ul a.active {
          background-color: #26b74f;
          color: #ffffff;
        }

.ContentContainer .NavList ul li:hover {
        background-color: #efefef;
      }

@media (max-width: 992px) {
      .ContentContainer .content-container .actual-content {
        margin: 0;
      }
}

.error-background {
  padding: 20px;
}

.error-background h1 {
    /* overriding from tg-base */
    font-size: 2em;
  }

.error-background .error-content span {
    /* overriding from tg-base */
    color: #1f1f1f;
  }

.Loader {
  background: #ffffff;
}

.Loader .loader-icon {
    background-size: initial;
    width: 300px;
    height: 100px;
    background-size: contain;
    background-position: center;
  }

.LanguageSelector * {
    margin: 0;
  }

.LanguageSelector .Select {
    
    background: none;
  }

.LanguageSelector .Select .Input__trailing-lane i {
        font-size: 14px;
        height: 14px;
        width: 14px;
      }

.LanguageSelector .Select .Input__trailing-lane i.icon:before {
          color: #ffffff;
        }

.LanguageSelector .Select__status-line {
      background: none;
    }

.LanguageSelector .Select select {
      color: #000000;
      background: none;
    }

.Dashboard .section.footer {
    padding: 0;
    margin: 0;
  }

.Dashboard .section.footer .LanguageSelector {
      margin: 0;
    }

.Dashboard .section.footer .Select .Input__trailing-lane i.icon:before {
            color: #000000;
          }

.Dashboard .section.footer .Select select {
        color: #000000;
      }

body .IndexContainerPnp {
  background-color: #015307;
}

body .IndexContainerPnp .main-content-container {
    margin-top: 0;
    background-position: top;
    background-size: 100% auto;
    color: #ffffff;
  }

@media (max-width: 992px) {

body .IndexContainerPnp .main-content-container {
      background-size: auto;
      background-position: bottom
  }
    }

body .IndexContainerPnp .main-content-container .index__header {
      padding: 20px;
    }

body .IndexContainerPnp .main-content-container .index__header .index__header__logo {
        display: none;
      }

body .IndexContainerPnp .main-content-container .index__header .index__header__actions {
        align-items: center;
      }

body .IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__menu {
          display: initial;
        }

body .IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__menu img {
            height: auto;
          }

body .IndexContainerPnp .main-content-container .index__header .index__header__actions .index__header__actions__home {
          display: none;
        }

body .IndexContainerPnp .main-content-container .index-container-full .fixed-position {
        margin-top: 10px;
      }

body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .PayNPlay {
          margin-top: 30px;
        }

body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .brand-description {
          font-size: 25px;
          line-height: 1.1;
        }

body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .brand-description p {
            margin: 0;
          }

body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .actions .button {
            width: auto;
            padding: 0 3em;
            font-size: 2em;
            text-transform: uppercase;
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
          }

body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Features .feature.link .feature-image img, body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Features .feature .feature-image img, body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Carousel .feature.link .feature-image img, body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Carousel .feature .feature-image img {
                height: 70px;
              }

body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Features .feature.link .feature-content h1,
              body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Features .feature.link .feature-content h2,
              body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Features .feature.link .feature-content h3,
              body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Features .feature.link .feature-content h4,
              body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Features .feature.link .feature-content h5,
              body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Features .feature .feature-content h1,
              body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Features .feature .feature-content h2,
              body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Features .feature .feature-content h3,
              body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Features .feature .feature-content h4,
              body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Features .feature .feature-content h5,
              body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Carousel .feature.link .feature-content h1,
              body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Carousel .feature.link .feature-content h2,
              body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Carousel .feature.link .feature-content h3,
              body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Carousel .feature.link .feature-content h4,
              body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Carousel .feature.link .feature-content h5,
              body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Carousel .feature .feature-content h1,
              body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Carousel .feature .feature-content h2,
              body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Carousel .feature .feature-content h3,
              body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Carousel .feature .feature-content h4,
              body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Carousel .feature .feature-content h5 {
                margin: 10px 5px 5px;
                text-transform: uppercase;
                font-size: 16px;
              }

body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Features .feature.link .feature-content p, body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Features .feature .feature-content p, body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Carousel .feature.link .feature-content p, body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .Carousel .feature .feature-content p {
                margin: 0;
              }

@media (max-width: 320px) {
            body .IndexContainerPnp .main-content-container .index-container-full .fixed-position .actions .button {
              padding: 0 2.5em;
            }
    }

body .IndexContainerPnp .Footer {
    background-color: #015307;
  }

body .IndexContainerPnp .Footer .topSection {
      background-color: #015307;
    }

.is-account .IndexContainerPnp .main-content-container .index-container-full .fixed-position .actions {
          flex-direction: column;
          align-items: center;
        }

.is-account .IndexContainerPnp .main-content-container .index-container-full .fixed-position .actions .button {
            width: -moz-fit-content;
            width: fit-content;
          }

.is-account .IndexContainerPnp .main-content-container .index-container-full .fixed-position .actions .sign-in .button {
              background: none;
              box-shadow: none;
            }

@media (max-width: 992px) {
            .is-account .IndexContainerPnp .main-content-container .index-container-full .fixed-position .actions .button {
              font-size: 1.5em;
            }
    }

.SignUpContainer .bonus-item {
    background: #ffffff;
    margin: 10px 0;
  }

.SignUpContainer .bonus-item .bonus-content-wrapper {
      padding: 20px;
    }

.SignUpContainer .account-information {
    line-height: normal;
  }

.SignUpContainer .account-information p {
      margin: 5px 0;
    }

.SignUpContainer .promo {
    flex-direction: column;
    margin-top: 15px;
  }

.SignUpContainer .promo .tg-input {
      width: 100%;
    }

.SignUpContainer .promo .tg-input .Input {
        margin: 0;
      }

.SignUpContainer .promo .promo-button {
      align-self: center;
      width: initial !important;
      padding-left: 10px;
      padding-right: 10px;
      min-width: 33.3%;
    }

.SignUpContainer .promo .layout-item-8 {
      width: 100% !important;
      margin: 0;
    }

.SignInContainer .sign-in-wrapper input[type='checkbox'] {
      margin: 0 8px 0 0;
      top: 5px;
    }

.SignInContainer .sign-in-wrapper a[href='/sign-up'] {
      background: none;
      color: #000000;
      text-decoration: underline;
      font-weight: normal;
      display: initial;
      width: auto;
    }

.SignInContainer .sign-in-wrapper a[href='/sign-up'] span {
        display: initial;
      }

@media (max-width: 992px) {
    .SignInContainer .sign-in-wrapper {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

.ContentPreviewContainer {
  z-index: 99999;
  color: #ffffff;
}

.PromotionsContainer .promotions-grid .Promotion {
    background: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

.PromotionsContainer .promotions-grid .Promotion .promotion-content-wrapper {
      padding: 30px;
    }

.PromotionDetailContainer .promotion-banner .banner-content {
    background: transparent;
  }

.Checkbox {
  position: relative;
}

.Checkbox .error-text {
    z-index: 10;
    position: absolute;
    left: 10px;
    transform: translate(0, -20%);
    margin: 0;
    background: #737373;
    color: #ffffff;
    border-radius: 2px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    font-size: 0.8em;
    padding: 6px;
  }

.InternalMessagesContainer .messages-list-item {
      background: #dcdcdc;
    }

.InternalMessagesContainer .messages-list-item .header-row .title {
          margin-bottom: 0;
        }

.InternalMessagesContainer .messages-list-item .header-row .time {
          color: #1f1f1f;
        }

.InternalMessagesContainer .messages-list-item:not(.open) .message-wrapper::after,
      .InternalMessagesContainer .messages-list-item:not(.open) .message-wrapper::before {
        background: linear-gradient(transparent, #dcdcdc);
      }

.InternalMessagesContainer .messages-list-item:not(.open):hover {
      background: #dcdcdc;
    }

.InternalMessagesContainer .messages-list-item:not(.open):hover .message-wrapper::after {
        background: linear-gradient(transparent, #dcdcdc);
      }

.notification {
  background: #ffffff;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.notification .notification-button {
    color: inherit;
  }

.notification .notification-button:hover {
      color: inherit;
    }

@media (max-width: 600px) {
  .notification {
    top: calc(88px + 10px);
  }

  .jurisdiction-sga.is-authenticated .notification {
    top: calc(88px + 30px + 10px);
  }
}

.Popup .Popup__content .Popup__header {
      color: #ffffff;
    }

.Popup .Popup__children .RealityCheckContainer .actions .button:last-child {
            width: 100%;
            margin: 15px 0;
          }

.SuccessfulDepositContainer img {
    width: 15%;
  }

.LimitConfirmation {
  color: #fff;
}

.IndexContainerPnp
  .index-background-active
  + .fixed-position
  .brand-description,
.IndexContainerPnp .index-background-active + .fixed-position .Carousel,
.IndexContainerPnp .index-background-active + .fixed-position .Features {
  display: none;
}

.PayNPlay .amount-header,
  .PayNPlay .auth-header,
  .PayNPlay .AcceptUpdatedTaC,
  .PayNPlay .prompt-header,
  .PayNPlay .SetLimitsStep__header,
  .PayNPlay .SetLimitsStep__summary,
  .PayNPlay .SetLimitsStep__notice {
    color: #ffffff;
  }

.PayNPlay .button {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
    text-decoration: underline;
  }

.PayNPlay .button:hover {
      opacity: 0.6;
    }

.PayNPlay form {
    flex-flow: column;
  }

.PayNPlay .tg-input {
    padding-bottom: 30px;
    max-width: 400px;
    width: 100%;
  }

.PayNPlay .tg-input__status-text {
      width: 100%;
      position: absolute;
      left: 50%;
      right: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
      bottom: -7px;
      background: none;
      color: #b72626;
      border-radius: 0px;
      box-shadow: none;
      pointer-events: none;
      font-size: 1em;
      padding: 6px;
    }

.PayNPlay .Amount form, .PayNPlay .Contact form {
      display: flex;
      align-items: center;
      justify-content: center;
    }

.PayNPlay .Amount form button, .PayNPlay .Contact form button {
        margin-bottom: 15px;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
      }

.PayNPlay .Amount__custom-amount .tg-input .Input, .PayNPlay .Contact__custom-amount .tg-input .Input {
          margin: 0;
          background: none;
        }

.PayNPlay .Amount__custom-amount .tg-input .Input__status-line, .PayNPlay .Contact__custom-amount .tg-input .Input__status-line {
            background: none;
          }

.PayNPlay .Amount__custom-amount .tg-input .Input__trailing-lane, .PayNPlay .Contact__custom-amount .tg-input .Input__trailing-lane {
            display: none;
          }

.PayNPlay .Amount__custom-amount .tg-input .Input input, .PayNPlay .Contact__custom-amount .tg-input .Input input {
            background: none;
            text-align: center;
            color: #ffffff;
            font-size: 30px !important;
            width: 100% !important;
            font-weight: 900;
          }

@media (max-width: 400px) {

.PayNPlay .Amount__custom-amount .tg-input .Input input, .PayNPlay .Contact__custom-amount .tg-input .Input input {
              font-size: 25px !important
          }
            }

.PayNPlay .Amount__custom-amount .tg-input .Input input::placeholder, .PayNPlay .Contact__custom-amount .tg-input .Input input::placeholder {
              color: #ffffff;
              opacity: 0.5;
            }

.PayNPlay .Amount__question .button, .PayNPlay .Contact__question .button {
        background: none;
        box-shadow: none;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
      }

.PayNPlay .Amount__question .button span, .PayNPlay .Contact__question .button span {
          text-decoration: underline;
        }

.PayNPlay .Contact .tg-input .Input {
        max-width: 400px;
        background: none;
      }

.PayNPlay .Contact .tg-input .Input input {
          width: 100%;
          background: none;
          font-size: 16px;
          text-align: center;
          color: #ffffff;
          border-bottom: 1px solid #ffffff;
          margin: auto;
        }

.PayNPlay .Contact .tg-input .Input input::placeholder {
            color: #ffffff;
            opacity: 0.5;
          }

.PayNPlay .Contact .phone-wrapper .calling-code {
        position: absolute;
        z-index: 1;
        background: none;
      }

.PayNPlay .Contact .phone-wrapper .calling-code select {
          color: #ffffff;
          padding: 0;
          font-size: 16px;
        }

.PayNPlay .Contact .phone-wrapper .calling-code .select-icon {
          right: 0;
        }

.PayNPlay .Contact .communication-consent {
      display: flex;
      justify-content: center;
      margin: 15px 0;
      color: #ffffff;
    }

.PayNPlay .Contact .communication-consent .flex label {
          width: 100%;
        }

.BetslipTab {
  box-shadow: -1px 0 6px 0 rgba(0, 0, 0, 0.3);
  width: auto;
  left: 50px;
  right: 50px;
  height: 40px;
  border-bottom: none;
  color: #fff;
  font-weight: bold;
  transition: 0.2s all;
  bottom: 0;
}

.BetslipTab.active {
    transition: 0.2s all;
    bottom: 70px;
  }

@supports (bottom: env(safe-area-inset-bottom)) {

.BetslipTab.active {
      bottom: calc(70px + env(safe-area-inset-bottom, 10px))
  }
    }

.BetslipTab.active.hide {
      transition: 0.2s all;
      bottom: 10px;
    }

@supports (bottom: env(safe-area-inset-bottom)) {

.BetslipTab.active.hide {
        bottom: calc(10px + env(safe-area-inset-bottom, 10px))
    }
      }

.PlayContainer .GameHeader__header {
    background: #ffffff;
  }

.PlayContainer .GameHeader__header img {
      height: 80%;
      padding-right: 10px;  
    }

body:not(.is-authenticated) .ModernGameView .GameHeader header {
  background: #ffffff; 
}

.SourceOfWealthContainer .occupation .alternatives {
    margin-top: 0;
  }

.SourceOfWealthContainer .tertiary {
    color: #42cc5f;
    border: 1px solid #000000;
    background-color: #ffffff;
  }

.SourceOfWealthContainer .tertiary:hover{
      color: #42cc5f;
      border: 1px solid #000000;
      background-color: #ffffff;
    }

.SourceOfWealthContainer .completed h2 {
      color: #000000;
    }

.SourceOfWealthContainer .alternatives .tg-select .Select__status-line {
        background: none;
      }

.SourceOfWealthContainer .alternatives textarea {
      border-radius: 4px;
      font-family: inherit;
    }

c .SourceOfWealthContainer .alternatives textarea::placeholder {
        color: #000000;
      }

.SourceOfWealthContainer .alternatives .character-count {
      color: #000000;
    }

@media (min-width: 1025px) {
        .GameGridList .category-row.category-row .slider .list .track .items .slide-item {
          min-width: calc(calc(100vw / 16) - 6.25px);
          height: 54px;
        }
          @media (max-width: 2400px) {
        .GameGridList .category-row.category-row .slider .list .track .items .slide-item {
            min-width: calc(calc(100vw / 12) - 8.3px)
        }
          }
          @media (max-width: 1600px) {
        .GameGridList .category-row.category-row .slider .list .track .items .slide-item {
            min-width: calc(calc(100vw / 10) - 10px)
        }
          }

          @media (max-width: 1200px) {
        .GameGridList .category-row.category-row .slider .list .track .items .slide-item {
            min-width: calc(calc(100vw / 8) - 12.5px)
        }
          }
  }

@media (max-width: 1025px) {
      .GameGridList .category-row .slider .list .track .items .slide-item {
        min-width: 40vw;
        height: 80px;
        /* This is so the scroll bar wont be placed on top of the title */
        margin-bottom: 3px;
      }
        .GameGridList .category-row .slider .list .track .items .slide-item:last-child {
          margin-right: 10px;
        }
        @media (min-width: 700px) {
      .GameGridList .category-row .slider .list .track .items .slide-item {
          min-width: 20vw
      }
        }
        @media (min-width: 992px) {
      .GameGridList .category-row .slider .list .track .items .slide-item {
          min-width: 12.5vw;
          width: 12.5vw
      }
        }
        .GameGridList .category-row .slider .list .track .items .slide-item .CasinoCategoryRow {
          width: 100%;
        }
          .GameGridList .category-row .slider .list .track .items .slide-item .CasinoCategoryRow a {
            display: flex;
            flex-flow: column;
            height: 100%;
            text-decoration: none;
          }
            .GameGridList .category-row .slider .list .track .items .slide-item .CasinoCategoryRow a .category-name {
              position: inherit;
            }
              .GameGridList .category-row .slider .list .track .items .slide-item .CasinoCategoryRow a .category-name .title {
                font-size: 12px;
              }
  }

.CasinoCategoryRow {
  height: 100%;
  width: 100%;
  text-align: center;
  position: relative;
}

.CasinoCategoryRow a .thumbnail {
      background: #efefef;
      border-radius: 6px;
    }

.CasinoCategoryRow a .thumbnail .placeholder-thumbnail {
        display: none;
      }

.CasinoCategoryRow a .thumbnail .category-icon {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 8px;
        z-index: 2;
      }

.CasinoCategoryRow a .thumbnail .category-icon img {
          max-height: 100%;
          max-width: 100%;
        }

.CasinoCategoryRow a .category-name {
      position: absolute;
      pointer-events: none;
      width: 100%;
      top: auto;
      left: auto;
      transform: none;
    }

.CasinoCategoryRow a .category-name .title {
        font-size: 14px;
      }

.CasinoCategoryRow a .overlay {
      display: none;
    }

@media (max-width: 992px) {

.SportsContainer .SpringBuilder {
      height: calc(
        var(--inner-height) - 88px -
          55px
      )
  }
    }
