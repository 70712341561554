.SignUpContainer {
  .bonus-item {
    background: var(--white);
    margin: 10px 0;

    .bonus-content-wrapper {
      padding: 20px;
    }
  }

  .account-information {
    line-height: normal;

    p {
      margin: 5px 0;
    }
  }

  .promo {
    flex-direction: column;
    margin-top: 15px;

    .tg-input {
      width: 100%;

      .Input {
        margin: 0;
      }
    }

    .promo-button {
      align-self: center;
      width: initial !important;
      padding-left: 10px;
      padding-right: 10px;
      min-width: 33.3%;
    }

    .layout-item-8 {
      width: 100% !important;
      margin: 0;
    }
  }
}
