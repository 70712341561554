.IndexPromotion {
  background-color: var(--light-gray);
  display: flex;
  align-items: flex-start;

  .content {
    max-width: 65%;

    p {
      margin: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:first-child {
        position: relative;
        display: inline;
        z-index: 0;

        &:after {
          content: '';
          position: absolute;
          height: 8px;
          width: 100%;
          background: var(--primary-color);
          bottom: 0;
          left: 0;
          z-index: -1;
        }
      }

      &:nth-child(2) {
        margin: 5px 0 0 0;
      }
    }
  }
}
