.Loader {
  background: var(--white);

  .loader-icon {
    background-size: initial;
    width: 300px;
    height: 100px;
    background-size: contain;
    background-position: center;
  }
}
