@import './colors.css';

.CasinoCategoryMenu {
  background-color: var(--light-gray);
  margin: 0;
  max-width: initial;
  padding-left: 10px;

  .casino-search {
    .search-input {
      max-width: calc(100% - 45px);
      width: 500px;
      .Input {
        margin: 0;
        input {
          /* overriding color from tg-base */
          margin-left: 0;
          color: var(--primary-text-color);
          &::placeholder {
            color: var(--dark-gray);
          }
        }
        &__status-line {
          background: none;
        }
      }
    }
  }

  .categories-menu {
    font-weight: bold;

    .category-item {
      height: 100%;
      margin: 0;
      border-radius: 0;
      padding: 0 15px;
      line-height: 50px;

      &:first-child {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }

      &:not(:first-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }

      &.active {
        background-color: var(--white);
        position: relative;

        &:after {
          content: '';
          position: absolute;
          height: 3px;
          background: var(--primary-color);
          bottom: 0;
          width: 100%;
          left: 0;
        }
      }
    }
  }
}
