.skeleton {
  background: rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.2) 5%,
    rgba(0, 0, 0, 0.1) 15%,
    rgba(0, 0, 0, 0.2) 25%
  );
  background-size: 300% 100%;
  animation: skeletonAnimation 2.5s ease infinite;
  display: inline-block;
}
.skeleton.bold {
    background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.3) 5%,
      rgba(0, 0, 0, 0.1) 15%,
      rgba(0, 0, 0, 0.3) 25%
    );
  }
.skeleton.line {
    margin: 2px 0;
    width: 80px;
    height: 100%;
    border-radius: 2px;
  }
.skeleton.line:before {
      content: '\00a0';
    }
.skeleton.circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
@keyframes skeletonAnimation {
  0% {
    background-position: 300%;
  }
  100% {
    background-position: 0%;
  }
}
