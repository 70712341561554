.error-background {
  padding: 20px;

  h1 {
    /* overriding from tg-base */
    font-size: 2em;
  }

  .error-content span {
    /* overriding from tg-base */
    color: var(--primary-text-color);
  }
}
