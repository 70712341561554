@import './colors.css';

.LanguageSelector {
  * {
    margin: 0;
  }
  .Select {
    
    background: none;
    .Input__trailing-lane {
      i {
        font-size: 14px;
        height: 14px;
        width: 14px;
        &.icon:before {
          color: var(--white);
        }
      }
    }
    &__status-line {
      background: none;
    }
  
    select {
      color: var(--black);
      background: none;
    }
  }
}


.Dashboard {
  .section.footer {
    padding: 0;
    margin: 0;

    .LanguageSelector {
      margin: 0;
    }
    .Select {
      .Input__trailing-lane {
        i {
          &.icon:before {
            color: var(--black);
          }
        }
      }
    
      select {
        color: var(--black);
      }
    }
  }
}