.CasinoContainer {
  .GameGridList {
    padding: 30px 0;

    .GameGrid {
      .grid-header {
        align-items: center;
        justify-content: space-between;
        margin: 0 auto 15px auto;
        padding: 0;
        max-width: calc(94vw - 6px);

        a {
          background-color: var(--primary-btn-background);
          color: var(--white);
          font-weight: bold;
          font-size: 0.6em;
          padding: 5px 7px;
          margin: 0;
          text-transform: uppercase;

          &:hover {
            background-color: var(--primary-btn-background-hover);
            opacity: 1;
          }
        }
      }

      &:not(.search-grid) {
        margin-bottom: 30px;
        .slider {
          .slide-item {
            .last-slide {
              a {
                background: var(--primary-btn-background);
                color: var(--white);
              }
            }
          }
        }
        .arrow {
          i {
            display: none;
          }
          &--next {
            background: rgba(35, 35, 35, 0.8);

            &:before {
              display: block;
              content: '';
              position: absolute;
              height: 21px;
              width: 12px;
              background-image: url('../images/back-arrow.svg');
              transform: rotateY(180deg);
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }
          }

          &--prev {
            background: rgba(35, 35, 35, 0.8);

            &:before {
              display: block;
              content: '';
              position: absolute;
              height: 21px;
              width: 12px;
              background-image: url('../images/back-arrow.svg');
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 993px) {
  .CasinoContainer {
    .GameGridList {
      padding: 15px 0;

      .GameGrid {
        .grid-header {
          margin: 0 10px;
          max-width: 100vw;
        }

        &:not(.search-grid) {
          margin-bottom: 15px;
        }
      }
    }
  }
}
