@import './colors.css';

.SourceOfWealthContainer {
  .occupation .alternatives {
    margin-top: 0;
  }

  .tertiary {
    color: var(--primary-color);
    border: 1px solid var(--black);
    background-color: var(--white);

    &:hover{
      color: var(--primary-color);
      border: 1px solid var(--black);
      background-color: var(--white);
    }
  }

  .completed {
    h2 {
      color: var(--black);
    }
  }

  .alternatives {
    .tg-select {
      .Select__status-line {
        background: none;
      }
    }
    textarea {
      border-radius: 4px;
      font-family: inherit;
      c &::placeholder {
        color: var(--black);
      }
    }

    .character-count {
      color: var(--black);
    }
  }
}