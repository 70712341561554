@import '../skin/theme/colors.css';

button,
.primary-button,
.secondary-button,
.button {
  white-space: nowrap;
  padding: 0 3em;
  font-size: 1em;
  line-height: 3em;
  letter-spacing: -0.3px;
  display: inline-block;
  text-decoration: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
  text-shadow: none;
  transition: all 0.2s ease-out;
  span,
  i,
  img {
    display: inline-block;
  }

  &:hover {
    transition: all 0.2s ease-out;
  }

  &.primary,
  .primary-button {
    color: var(--primary-btn-color);
    background: var(--primary-btn-background);
    &:hover {
      background: var(--primary-btn-background-hover);
    }
  }

  &.secondary,
  .secondary-button {
    color: var(--secondary-btn-color);
    background: var(--secondary-btn-background);
    &:hover {
      background: var(--secondary-btn-background-hover);
    }
  }

  &.tertiary {
    background: var(--white);
    color: var(--dark-gray);
  }

  &.dark {
    color: rgb(106, 106, 106);
    font-weight: 400;

    &:hover {
      color: var(--white);
    }
  }

  &.tiny {
    font-size: 0.9em;
    line-height: 1em;
    padding: 8px;
    font-weight: 900;
    letter-spacing: 0.5px;
  }

  &.small {
    font-size: 0.8em;
  }

  &.large {
    font-size: 1.3em;
  }

  &.wide {
    display: block;
    width: 100%;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }

  &.icon {
    padding: 4px;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 29px;
      height: 29px;
      font-size: 18px;
      background-size: 100%;
    }
  }

  &.link {
    font-weight: normal;
    background: none;
    padding: 0;
    text-transform: none;
    font-size: inherit;
    text-decoration: underline;
    line-height: inherit;

    > span {
      display: inline;
    }
  }

  &.loading:not(.icon) {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 25px;
      display: inline-block;
      margin-left: -30px;
      margin-right: 5px;
    }
  }
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
}
