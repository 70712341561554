*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: #ffffff;
  font-family: 'IBM Plex Sans', 'Fira Sans', sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  background-color: #3d3d3d;
  -webkit-font-smoothing: antialiased;
}

body .container {
    max-width: 2560px;
    margin-left: auto;
    margin-right: auto;
  }

a {
  color: inherit;
}

.button,
button,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'IBM Plex Sans', 'Fira Sans', sans-serif;
}

.container {
  margin: 0 auto;
  position: relative;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline-width: 0;
}

input {
  outline: none;
}

input[type='text'],
input[type='email'],
input[type='password'] {
  border: none;
  font-size: 14px;
  letter-spacing: -0.5px;
  text-align: left;
  font-weight: normal;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  display: block;
  font-weight: bold;
}

.h1,
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

.h2,
h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}

.h3,
h3 {
  font-size: 1.17em;
  margin: 1em 0;
}

.h4,
h4 {
  margin: 1.33em 0;
}

.h5,
h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}

.h6,
h6 {
  font-size: 0.67em;
  margin: 2.33em 0;
}

.layout-item {
  display: flex;
}

.layout-item > * {
    flex: 1 1 1%;
    margin: 0 0.5em 0 0;
  }

.layout-item > *:last-child {
      margin: 0;
    }

.layout-item-full-width {
  width: 100% !important;
}

.layout-item-3 {
  display: inline-block;
  width: 25% !important;
  vertical-align: top;
}

.layout-item-4 {
  display: inline-block;
  width: 33.33% !important;
  vertical-align: top;
}

.layout-item-6 {
  display: inline-block;
  width: 50% !important;
  vertical-align: top;
}

.layout-item-8 {
  display: inline-block;
  width: 66.66% !important;
  vertical-align: top;
}

.layout-item-9 {
  display: inline-block;
  width: 75% !important;
  vertical-align: top;
}

/* custom text utilities */

.text-upper {
  text-transform: uppercase;
}

/* custom margin */

.m-l-1 {
  margin-left: 1rem !important;
}

.m-l-d5 {
  margin-left: 0.5rem !important;
}

.m-r-1 {
  margin-right: 1rem !important;
}

.m-r-d5 {
  margin-right: 0.5rem !important;
}

#fc_frame:not(.fc-open) {
  display: none;
  transition: bottom 0.2s ease-in-out;
}

@media (max-width: 992px) {
  #launcher:not(.open) {
    /* Important required to override Zendesks style */
    opacity: 0 !important;
  }
}

.is-desktop.is-offcanvas-open #launcher:not(.open) {
    /* Important required to override Zendesks style */
    opacity: 0 !important;
  }

.piq-cashier-wrapper {
  visibility: hidden;
}
