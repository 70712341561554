.tg-input,
.tg-select,
.tg-phonenumber-input,
.tg-password-input,
.tg-birthdate-input {
  position: relative;

  .Input,
  .Select {
    margin-bottom: 20px;
    background: var(--white);
    position: relative;
    &__leading-lane,
    &__trailing-lane {
      i.icon:not(.icon-check-circle):before {
        color: var(--primary-text-color);
      }
    }
  }

  .Select {
    .Input__trailing-lane {
      i.icon-caret-down {
        &:before {
          content: '\e920';
        }
      }
    }
  }

  &__status-text {
    min-height: 0;
  }

  &--status-failure &__status-text {
    width: auto;
    z-index: 10;
    position: absolute;
    left: 10px;
    bottom: 0;
    transform: translate(0, 70%);
    background: var(--dark-gray);
    color: var(--white);
    border-radius: 2px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    font-size: 0.8em;
    padding: 6px;
  }
}

.SourceOfWealthContainer .occupation .tg-input input {
  border: 1px solid var(--black);
  border-radius: 8px;
}
