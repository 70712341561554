@import './colors.css';

.OffCanvas {
  .canvas-content {
    background: var(--light-gray);

    .canvas-header {
      span {
        display: none;
      }

      .canvas-close,
      .canvas-back {
        img {
          height: 28px;
          width: auto;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .OffCanvas {
    height: 100%;
    .canvas-content {
      padding: 20px 20px 100px;
    }
  }
}

/* Dashboard index */
.canvas-content {
  .notifications-badge.notifications-badge--right:after {
    color: var(--white);
    margin: auto;
    top: 0;
    bottom: 0;
    right: 45px;
  }

  .section {
    &.membership-buttons {
      flex-wrap: nowrap;
      .button {
        width: 100%;
        text-align: center;
      }
    }
    &.initials {
      color: var(--white);
    }

    &.products {
      order: 3;
    }

    &.payment-buttons,
    &.membership-buttons {
      order: 2;
      .button:first-child {
        margin: 0 5px 0 0;
      }
      .button:last-child {
        margin: 0 0 0 5px;
      }
    }

    &.profile-links {
      order: 4;
      margin-top: 20px;
    }

    &.support {
      order: 5;
      padding: 20px 0;
    }

    &.footer {
      order: 6;

      .button {
        display: none;
      }
    }

    &.profile-links,
    &.products {
      flex-direction: column;

      .ItemWithIconAndText {
        position: relative;
        margin: 1px;
        height: 68px;
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        background-color: var(--accordion-color);
        padding: 15px;

        .text {
          margin: 0;
          font-weight: bold;
        }

        .icon {
          width: 35px;
          height: 35px;
          margin-right: 10px;
        }

        &:after {
          display: block;
          content: '';
          position: absolute;
          height: 21px;
          width: 12px;
          background-image: url('../images/back-arrow.svg');
          transform: rotateY(180deg);
          top: 0;
          bottom: 0;
          right: 15px;
          margin: auto;
        }
      }
    }

    &.balance {
      order: 1;

      div:first-child {
        span {
          color: var(--primary-color);
        }
      }
    }
  }
}

.Dashboard {
  .balance {
    .refresh-button,
    .refresh-button.loading {
      /* not displayed by default */
      display: initial;

      i {
        color: var(--primary-color);
      }
    }
  }

  .navigation {
    order: 6;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;

    a {
      text-decoration: none;
      padding: 5px 0;

      &:hover {
        text-decoration: underline;
      }
    }

    @media (max-width: 992px) {
      display: flex;
    }
  }
}
