.AccordionWidget {
  .AccordionItem {
    &__header {
      background-color: var(--accordion-color);

      &:hover {
        background-color: var(--accordion-color);
      }
      .ResponsibleGamingWidget__limit-header-title,
      .title-text {
        font-weight: 900;
      }

      &__expand-icon {
        color: var(--dark-gray);
      }
    }

    &__content {
      background-color: var(--accordion-color);

      & div:is(.no-list-data) {
        + .navigation {
          display: none;
        }
      }
      & div:is(.tg-list) {
        .navigation {
          display: flex;
          justify-content: space-between;
        }
      }

      .table {
        background-color: var(--accordion-color);
      }

      .detail {
        background: var(--gray) !important;

        &:before {
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid var(--gray);
        }
      }
    }
  }

  .pagination {
    display: flex;

    li.disabled a {
      color: var(--dark-gray);
    }
  }
}
