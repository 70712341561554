.cashier-accordion {
  .AccordionItem {
    .payment-method-item-content {
      .account-selection {
        background: var(--white);
        color: inherit;
        &:hover {
          background: var(--white);
        }
      }

      .amount-selector {
        button {
          background: var(--dark-gray);
          color: var(--white);
        }
      }

      .custom-amount-and-submit {
        button {
          background: var(--primary-color);
        }
      }

      .notice.level-warning {
        background: #fff0bc;
        border-color: #ffd749;
        color: var(--black);
      }
    }
  }

  .payment-result-wrapper {
    background: var(--gray);

    .payment-result-content {
      .payment-result-balance {
        color: var(--primary-color);
      }
    }
  }

  .fill-in-steps {
    background: var(--gray);
  }
}

@media (max-width: 370px) {
  .cashier-accordion {
    .AccordionItem {
      &__header {
        .payment-method-item-header .provider-logo {
          width: 65px !important;
          margin-right: 5px;
        }
      }
    }
  }
}
