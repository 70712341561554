.header-wrapper {
  background-color: var(--white);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  .Header {
    &__background.Header__background--visible {
      background-color: var(--white);
    }

    &__support {
      display: none;
    }

    &__hamburger {
      margin-right: 10px;

      .hamburger-icon img {
        height: 20px;
      }

      @media (max-width: 992px) {
        display: none;
      }
    }

    &__topnav {
      margin: 0 auto;
      font-weight: bold;

      .top-nav-menu-item {
        margin: 0 15px;
        padding: 0;

        &.active {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            height: 3px;
            background: var(--primary-color);
            bottom: -4px;
            width: 100%;
            left: 0;
          }
        }
      }
    }

    &__profile {
      position: relative;

      .notifications-badge--right[data-notifications]:not(
          [data-notifications='']
        ):not([data-notifications='0'])::after {
        position: absolute;
        top: 0;
        left: 26px;
        transform: translate(-50%, -50%);
      }
    }

    &__logo img {
      height: 30px;
      width: auto;
    }

    &__actions {
      .button {
        font-size: 1em !important;

        @media (min-width: 993px) {
          padding: 0 3em;
          height: 50px;
          line-height: 50px;
          span {
            font-size: 1.5em !important;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  body:not(.is-authenticated) {
    .header-wrapper {
      .Header {
        flex-wrap: wrap;
        height: auto;
        padding: 0;

        &__logo {
          margin: 10px 0 10px 10px;
        }

        &__actions {
          width: 100vw;

          .MembershipActions {
            width: 100%;

            > a {
              display: block;
              margin: 0;
              width: 50%;
              text-align: center;
            }
          }
        }

        &__background {
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .header-wrapper {
    .Header {
      &__logo img {
        height: 20px;
      }
    }
  }
}
