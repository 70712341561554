.AcceptUpdatedTaC {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.AcceptUpdatedTaC > * {
    margin-bottom: 10px;
  }

.AcceptUpdatedTaC header {
    margin-top: 10px;
    text-align: center;
    order: 1;
  }

.AcceptUpdatedTaC .button {
    order: 2;
  }

.AcceptUpdatedTaC__content {
    margin: 0 auto;
    max-width: 500px;
    border-radius: 2px;
    padding: 0 10px;
    height: 400px;
    overflow-y: scroll;
    background-color: #e5e5e5;
    color: #000000;
    order: 3;
    text-align: left;
  }

.AcceptUpdatedTaC__content * {
      white-space: normal;
      word-break: break-word;
    }

.AcceptUpdatedTaC__content h1,
    .AcceptUpdatedTaC__content h2,
    .AcceptUpdatedTaC__content h3,
    .AcceptUpdatedTaC__content h4,
    .AcceptUpdatedTaC__content h5,
    .AcceptUpdatedTaC__content h6 {
      font-size: 1em;
      font-weight: bold;
    }
