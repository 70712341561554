.IndexContainerPnp
  .index-background-active
  + .fixed-position
  .brand-description,
.IndexContainerPnp .index-background-active + .fixed-position .Carousel,
.IndexContainerPnp .index-background-active + .fixed-position .Features {
  display: none;
}

.PayNPlay {
  .amount-header,
  .auth-header,
  .AcceptUpdatedTaC,
  .prompt-header,
  .SetLimitsStep__header,
  .SetLimitsStep__summary,
  .SetLimitsStep__notice {
    color: var(--white);
  }

  .button {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
    text-decoration: underline;

    &:hover {
      opacity: 0.6;
    }
  }

  form {
    flex-flow: column;
  }

  .tg-input {
    padding-bottom: 30px;
    max-width: 400px;
    width: 100%;
    &__status-text {
      width: 100%;
      position: absolute;
      left: 50%;
      right: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
      bottom: -7px;
      background: none;
      color: #b72626;
      border-radius: 0px;
      box-shadow: none;
      pointer-events: none;
      font-size: 1em;
      padding: 6px;
    }
  }

  .Amount,
  .Contact {
    form {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        margin-bottom: 15px;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
      }
    }
    &__custom-amount {
      .tg-input {
        .Input {
          margin: 0;
          background: none;
          &__status-line {
            background: none;
          }
          &__trailing-lane {
            display: none;
          }
          input {
            background: none;
            text-align: center;
            color: var(--white);
            font-size: 30px !important;
            width: 100% !important;
            font-weight: 900;

            @media (max-width: 400px) {
              font-size: 25px !important;
            }

            &::placeholder {
              color: var(--white);
              opacity: 0.5;
            }
          }
        }
      }
    }

    &__question {
      .button {
        background: none;
        box-shadow: none;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
        span {
          text-decoration: underline;
        }
      }
    }
  }
  .Contact {
    .tg-input {
      .Input {
        max-width: 400px;
        background: none;
        input {
          width: 100%;
          background: none;
          font-size: 16px;
          text-align: center;
          color: var(--white);
          border-bottom: 1px solid var(--white);
          margin: auto;
          &::placeholder {
            color: var(--white);
            opacity: 0.5;
          }
        }
      }
    }
    .phone-wrapper {
      .calling-code {
        position: absolute;
        z-index: 1;
        background: none;
        select {
          color: var(--white);
          padding: 0;
          font-size: 16px;
        }
        .select-icon {
          right: 0;
        }
      }
    }
    .communication-consent {
      display: flex;
      justify-content: center;
      margin: 15px 0;
      color: var(--white);
      .flex {
        label {
          width: 100%;
        }
      }
    }
  }
}
