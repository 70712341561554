@import './colors.css';

.PlayContainer {
  .GameHeader__header {
    background: var(--white); 

    img {
      height: 80%;
      padding-right: 10px;  
    }
  }
}

body:not(.is-authenticated) .ModernGameView .GameHeader header {
  background: var(--white); 
}