.casino-promotion {
  background-color: var(--light-gray);
  margin: 0 0 30px 0;

  .summary {
    line-height: 2.5em;
  }

  .casino-promotion-bg {
    &::before,
    &::after {
      display: none;
    }
  }
}

@media (max-width: 992px) {
  .casino-promotion {
    margin: 0 0 15px 0;

    .container-max-width {
      padding: 15px 10px;
    }
    .actions {
      margin: 0;
    }
  }
}
