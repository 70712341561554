@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('OpenSans-Light'),
    url('/skin/fonts/OpenSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: local('OpenSans-LightItalic'),
    url('/skin/fonts/OpenSans-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('OpenSans-Regular'),
    url('/skin/fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('OpenSans-Italic'),
    url('/skin/fonts/OpenSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('OpenSans-Bold'),
    url('/skin/fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: local('OpenSans-BoldItalic'),
    url('/skin/fonts/OpenSans-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 900;
  src: local('OpenSans-ExtraBold'),
    url('/skin/fonts/OpenSans-ExtraBold.ttf') format('truetype');
}
