.Checkbox {
  display: flex;
  align-items: center;
}

.Checkbox__input-wrapper {
    margin: 0;
    width: 45px;
    height: 20px;
    background: #737373;
    border-radius: 99px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

.Checkbox__input-wrapper::after {
      top: 0;
      bottom: 0;
      margin: auto;
      left: 4px;
      pointer-events: none;
      position: absolute;
      content: '';
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: #fff;
      transition: all 0.2s ease-in-out;
    }

.Checkbox__label {
    margin-left: 10px;
    width: calc(100% - 45px);
  }

.Checkbox--has-value .Checkbox__input-wrapper {
      background: #42cc5f;
    }

.Checkbox--has-value .Checkbox__input-wrapper::after {
        left: calc(100% - 14px - 4px);
      }

.Checkbox input[type='checkbox'] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    margin: 0;
    top: 0;
    left: 0;
  }
