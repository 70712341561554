.IndexContainerPnp .InstallApp {
  margin-top: 0;
}

.InstallApp {
  color: var(--white);
  text-shadow: 0 5px 5px rgb(0 0 0 / 30%);
}

.InstallAppContainer .Popup {
  &__header {
    text-shadow: 0 5px 5px rgb(0 0 0 / 30%);
  }
}

body[data-install-app='true'].page-casino,
body[data-install-app='true'].page-live-casino {
  .InstallApp {
    margin-top: var(--header-height);
  }
}
@media (max-width: 992px) {
  body[data-install-app='true'].page-casino,
  body[data-install-app='true'].page-live-casino {
    .InstallApp {
      margin-top: calc(
        var(--header-height-mobile) + var(--sticky-footer-height)
      );
    }

    .Banners {
      margin-top: 0;
      min-height: calc(60vh - var(--install-app-banner-height)) !important;
      max-height: calc(60vh - var(--install-app-banner-height)) !important;
    }
  }
}
