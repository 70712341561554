.StickyFooter-mobile {
  top: var(--header-height-mobile);
  z-index: 0;
  background-color: var(--gray);
  box-shadow: -1px 0 6px 0 rgba(0, 0, 0, 0.3);
  width: 100%;
  overflow: hidden;
  transition: 0.2s all;
  margin-left: 0;
  margin-right: 0;

  &.hide {
    transition: 0.2s all;
    transform: translateY(-100%);
  }

  a {
    border-left: solid 1px var(--light-gray);
    font-size: 10px;

    &.active {
      background-color: var(--primary-btn-background);
      color: var(--white);

      img {
        filter: invert(100%);
      }
    }
  }

  .hamburger-icon,
  .exit-sidebar-icon {
    .notifications-badge {
      display: none;
    }

    img {
      width: 18px;
    }
  }

  .profile-icon {
    position: relative;

    .notifications-badge--right[data-notifications]:not(
        [data-notifications='']
      ):not([data-notifications='0'])::after {
      position: absolute;
      top: 2px;
      left: 55%;
      content: attr(data-notifications);
    }
  }

  img {
    height: 18px;
    width: 18px;
  }
}
