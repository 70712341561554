.SportsContainer {
  .SpringBuilder {
    @media (max-width: 992px) {
      height: calc(
        var(--inner-height) - var(--header-height-mobile) -
          var(--sticky-footer-height)
      );
    }
  }
}
