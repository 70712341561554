.ResponsibleGamingWidget {
  .Select, .Input {
    margin: 0;
  }
  &__timespan-limit-selector {
    margin: 8px 0;
    .button {
      padding: 0;
      background: var(--primary-color);
    }
  }
}
