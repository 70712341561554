.SignInContainer {
  .sign-in-wrapper {
    input[type='checkbox'] {
      margin: 0 8px 0 0;
      top: 5px;
    }

    a[href='/sign-up'] {
      background: none;
      color: var(--black);
      text-decoration: underline;
      font-weight: normal;
      display: initial;
      width: auto;

      span {
        display: initial;
      }
    }
  }

  @media (max-width: 992px) {
    .sign-in-wrapper {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
