.ContentContainer {
  .content-container .content > div {
    background: var(--white);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 15px;
    margin: auto 10px;
    width: 100%;
  }

  @media (max-width: 992px) {
    pre code {
      white-space: pre-line;
      font-size: 0.875em;
    }
  }

  .NavList {
    ul {
      background-color: var(--white);

      a {
        color: var(--primary-text-color);
        font-weight: 900;

        &.active {
          background-color: var(--primary-btn-background);
          color: var(--primary-btn-color);
        }
      }

      li:hover {
        background-color: var(--light-gray);
      }
    }
  }
}

@media (max-width: 992px) {
  .ContentContainer {
    .content-container {
      .actual-content {
        margin: 0;
      }
    }
  }
}
