.InternalMessagesContainer {
  .messages-list {
    &-item {
      background: var(--accordion-color);

      .header-row {
        .title {
          margin-bottom: 0;
        }

        .time {
          color: var(--primary-text-color);
        }
      }
    }
    &-item:not(.open) {
      .message-wrapper::after,
      .message-wrapper::before {
        background: linear-gradient(transparent, var(--accordion-color));
      }
    }
    &-item:not(.open):hover {
      background: var(--accordion-color);
      .message-wrapper::after {
        background: linear-gradient(transparent, var(--accordion-color));
      }
    }
  }
}
