.leaderboard {
  padding: 20px;
  position: relative;
  background: #42cc5f;
}

.leaderboard table {
    width: 100%;
    padding: 10px;
    border-collapse: collapse;
  }

.leaderboard .leaderboard-header-item {
    color: #ffffff;
    text-align: left;
  }

.leaderboard .leaderboard-header-item th:last-child {
      text-align: right;
    }

.leaderboard .leaderboard-item {
    line-height: 40px;
  }

.leaderboard .leaderboard-item td {
      position: inherit;
      padding: 0 10px;
    }

.leaderboard .leaderboard-item td:last-child {
        text-align: right;
      }

.leaderboard .leaderboard-item-active {
    font-weight: bold;
  }

.leaderboard tbody.relative {
    border-top: 1px solid #ffffff;
  }

@media (max-width: 540px) {

.leaderboard {
    font-size: 0.9em
}
  }

.tournament-join .button.primary.icon {
    padding: 0 3em;
    display: flex;
    align-items: center;
  }

.tournament-join .button.primary.icon i {
      padding-right: 0;
    }

.tournament-join .button.primary.icon.loading {
      height: 42px;
    }

.tournament-join .button.primary.icon.loading svg {
        margin: 0;
      }

.tournament-join .button.primary.icon.loading circle {
        stroke: #ffffff;
      }
