body {
  --gameplay-header-bgcolor: var(--white);
  --gameplay-gamedetails-bgcolor: var(--white);
  --gameplay-loader-bgcolor: none;

  --gameplay-popunder-overlay-bgcolor: var(--overlay);

  --gameplay-friendly-sign-in-message-height: 0px;

  &.is-desktop,
  &.mobile {
    --gameplay-header-height: 55px;

    .PlayContainer {
      .GamePlay {
        .GamePlayHeader {
          &__name {
            display: none;
          }

          &__menu.button.icon {
            background-color: var(--white);

            i {
              color: var(--primary-color);
            }
          }

          &__menu-close.button.icon {
            i {
              color: #23a649;
            }
          }

          &__back {
            span {
              display: none;
            }
          }

          &__hamburger {
            border-radius: 8px;
            width: 35px;
            height: 35px;
            padding: 0;

            .hamburger-icon {
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                height: 15px;
              }
            }
          }

          .MembershipActions {
            height: 35px;
            .button {
              height: 100%;
              line-height: 35px;
            }
          }
        }

        @media (max-width: 992px) {
          .PopUnder {
            top: 0;

            &--closed {
              .PopUnder__content {
                transform: translateY(-100%);
              }
            }
            &__content {
              top: calc(
                var(--gameplay-header-height) + var(--gameplay-topbar-height) +
                  var(--gameplay-friendly-sign-in-message-height)
              );
            }
          }
        }
      }
    }
  }
}

.GamePlay {
  color: var(--primary-color);

  .GameDetails__categories-nav {
    & > div:first-child {
      width: 100%;
    }
  }

  .GameDetails__quick-actions,
  .GamePlayHeader__back,
  .GameDetails__name-and-fav {
    .button {
      &.icon {
        background-color: transparent;
        color: var(--black);
        width: 35px;
        height: 35px;
        padding: 0;

        i {
          width: 100%;
          height: 100%;
          color: var(--primary-color);
        }
      }
    }
  }

  .GamePlayHeader {
    &__menu.button.icon {
      background-color: var(--white);

      i {
        color: var(--primary-color);
      }
    }

    &__menu-close.button.icon {
      i {
        color: #23a649;
      }
    }
  }

  .GameFrame {
    &__footer {
      display: none;
    }
  }

  .GameDetails {
    &__actions {
      transform: translate(0);
      margin: 10px 0;

      .button {
        margin: 5px;
      }
    }

    &__categories-nav {
      .CasinoCategoryMenu {
        display: flex;
        align-items: center;
        background-color: transparent;

        &:before {
          @media (max-width: 992px) {
            background: linear-gradient(
              to right,
              rgba(var(--white), 0) 0%,
              var(--white) 80%
            );
          }
        }

        .casino-search {
          transition: none;

          .search-input {
            margin-left: 10px;
            border-radius: 8px;
          }

          &:not(.active) {
            width: 35px;
            height: 35px;
            border-radius: 8px;
          }

          .icon.icon-times {
            width: 35px;
            height: 35px;
            border-radius: 8px;
          }
        }

        .categories-menu {
          .category-item {
            border-radius: 8px;
            color: var(--black);
            border: none;

            &.active {
              color: var(--black);
            }

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    &__category {
      .slider {
        .slide-item {
          .last-slide {
            a {
              color: var(--white);
            }
          }
        }
      }
    }
  }

  .QuickDeposit {
    .Select {
      margin: 0;
      select {
        border: none;
        padding: 0;
      }
    }

    .Input__trailing-lane i.icon {
      right: 0;
      font-size: 12px;
      width: 12px;
      height: 12px;
      margin: 0;
      &:before {
        color: var(--primary-color);
      }
    }

    .QuickAmounts {
      * {
        box-shadow: none;
      }

      .button {
        i {
          font-size: 1em;
        }
      }

      .amount-buttons {
        > {
          &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
        }
      }

      .custom-amount {
        border-radius: 8px;
        .Input {
          margin: 0;
          &__status-line {
            background: none;
          }
        }
      }

      .action {
        background: var(--primary-btn-background);
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;

        &:hover {
          background: var(--primary-btn-background-hover);
        }
      }
    }
  }

  @media (max-width: 992px) {
    .PopUnder {
      top: 0;

      &--closed {
        .PopUnder__content {
          transform: translateY(-100%);
        }
      }
      &__content {
        top: calc(
          var(--gameplay-header-height) + var(--gameplay-topbar-height) +
            var(--gameplay-friendly-sign-in-message-height)
        );
      }
    }
  }
}
